import olAxios from '../api/Interceptors';

const AuthApi = {
  loginEmail: (data) => olAxios.post('/auth/login/email', data),
  login: (data) => olAxios.post('/auth/login', data),
  connectWallet: (data) => olAxios.post('/auth/connect-wallet/', data),
  connectEmail: (data) => olAxios.post('/auth/connect-email', data),
  register: (data) => olAxios.post('/auth/verify-email', data),
  loginGoogleSocial: (data) => olAxios.post('/auth/login/google', data),
  connectGoogle: (data) => olAxios.post('/auth/connect-google', data),
};

export default AuthApi;
