import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TEAM } from '../../../../assets/imgs';
import TButton from '../../common/Button';

function CardInviteMember(props) {
  const { member, newInviteMember, setNewInviteMember } = props;
  const { t } = useTranslation();
  const [isInvite, setIsInvite] = useState(false);
  const handleInvite = (item) => {
    setNewInviteMember((members) => [...members, item]);
  };
  const handleUnInvite = (item) => {
    const index = newInviteMember.findIndex((input) => input.id === item.id);
    const newMember = [...newInviteMember];
    newMember.splice(index, 1);
    setNewInviteMember(newMember);
  };
  useEffect(() => {
    setIsInvite(newInviteMember.findIndex((item) => item.id === member.id) > -1);
  }, [newInviteMember]);
  return (
    <div className="card-radius">
      <div className="info-member">
        <img className="icon-member" src={member.avatar || TEAM.avatarUser} alt="dgpub-network" />
        <span>{member.name}</span>
      </div>
      <div className="btn-view-invite">
        {!isInvite ? (
          <TButton
            title={t('referral.invite')}
            className="btn-invite"
            onClick={() => handleInvite(member)}
          />
        ) : (
          <TButton
            title={t('referral.undoInvitation')}
            className="btn-remove-invite"
            onClick={() => handleUnInvite(member)}
            type="reverse"
          />
        )}
      </div>
    </div>
  );
}

export default CardInviteMember;
