import React from 'react';

function BoxRewardIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M5 8h14v8c0 1.886 0 2.828-.586 3.414C17.828 20 16.886 20 15 20H9c-1.886 0-2.828 0-3.414-.586C5 18.828 5 17.886 5 16V8z"
        fill="#fff"
      />
      <path
        d="M12 8l-.24-2.153A2.654 2.654 0 009.124 3.5v0C7.683 3.5 6.5 4.667 6.5 6.106v0c0 .87.435 1.684 1.16 2.168L9.5 9.5M12 8l.24-2.153A2.654 2.654 0 0114.876 3.5v0c1.44 0 2.623 1.167 2.623 2.606v0c0 .87-.435 1.684-1.16 2.168L14.5 9.5"
        stroke="#fff"
        strokeLinecap="round"
      />
      <rect x={4} y={8} width={16} height={3} rx={1} fill="#fff" />
      <path d="M12 11v4" stroke="#fff" strokeLinecap="round" />
    </svg>
  );
}

export default BoxRewardIcon;
