import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/imgs';
import { MAX_TYPE_DATE } from '../../../constants';
import './styles.scss';

function InputField(props) {
  const {
    id,
    title,
    type,
    isRequired = false,
    placeholder,
    className,
    onChange,
    maxLength,
    minLength,
    value,
    typeInput,
    error = false,
    message = '',
    autoComplete = 'off',
    typeMaxDate,
    createMode,
    readonly = false,
    onKeyDown,
    disabled = false,
    ageLimit,
    accessoriesLeft = null,
    accessoriesRight = null,
    classNameInput = '',
  } = props;
  const maxDate = moment().format(`${ageLimit || MAX_TYPE_DATE}`);
  const typeNumber = 'Number';
  const typeDate = 'date';
  const typeEmail = 'email';
  const CREATE_MODE_AUTO = 1;
  const { t } = useTranslation();
  return (
    <div className={`my-input-group ${className}`} id={id}>
      {title && <h6>{title}</h6>}
      {type === typeEmail && Number(createMode) === CREATE_MODE_AUTO && (
        <div className="create-mode-note">{t('warning.note')}</div>
      )}

      <div className="wrap-input-custom">
        {accessoriesLeft && <div className="accessory-left">{accessoriesLeft}</div>}
        <input
          type={type}
          className={`input-custom ${accessoriesLeft && 'padding-left'} ${
            accessoriesRight && 'padding-right'
          } ${classNameInput}`}
          placeholder={placeholder}
          required={isRequired}
          onKeyDown={onKeyDown}
          onChange={(e) =>
            onChange(typeInput === typeNumber ? e.target.value.replace(/\D/g, '') : e.target.value)
          }
          max={typeMaxDate ? maxDate : null}
          maxLength={maxLength}
          value={value}
          autoComplete={autoComplete}
          min={minLength}
          readOnly={readonly}
          disabled={disabled}
        />
        {accessoriesRight && <div className="accessory-right">{accessoriesRight}</div>}
        {typeInput === typeDate && (
          <div className="icon-date-custom">
            <img src={ICONS.iconCalendar} alt="icon" />
          </div>
        )}
        {error && (
          <div className="waring-message">
            <h6>{message}</h6>
          </div>
        )}
      </div>
    </div>
  );
}

export default InputField;
