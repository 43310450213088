import React from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import './style.scss';
import { TICKET_TYPE_IMG } from '../../../../assets/imgs';
import ShowTxHash from '../ShowTxHash/ShowTxHash';
import RenderBtnResubmit from '../../pages/RedeemReward/RenderBtnResubmit';

function RedeemHistoryTable({ data = [], getRedeemRewardHistory }) {
  const { t } = useTranslation();
  return (
    <div className="table-style">
      <table>
        <thead>
          <tr />
          <tr>
            <td>{t('Game')}</td>
            <td>{t('Network')}</td>
            <td>{t('nft.status')}</td>
            <td className="text-end">{t('Reward Claimed')}</td>
            <td className="text-end">{t('filter.date')}</td>
            <td className="text-end">{t('redeemRewards.transaction')}</td>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <TableSection
              // eslint-disable-next-line
              key={`table-section-${index}`}
              data={item}
              index={index}
              getRedeemRewardHistory={getRedeemRewardHistory}
            />
          ))}
          {data?.length < 1 && (
            <tr className="odd">
              <td colSpan={100}>{t('myPrize.noData')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

const TableSection = ({ data, index, getRedeemRewardHistory }) => {
  const { t } = useTranslation();
  return (
    <>
      {/* eslint-disable-next-line */}
      <tr key={`body-${index}`}>
        <td data-label={t('Game')}>
          <div className="game-ticket-wrapper">
            <img alt="game-img" src={data?.applicationGame?.thumnail?.url} className="game-img" />
            <p>{data?.applicationGame?.name}</p>
          </div>
        </td>
        <td data-label={t('Network')}>
          <p>{data?.network?.network_name}</p>
        </td>
        <td data-label={t('nft.status')}>
          <RenderStatusRedeem data={data} />
        </td>
        <td data-label={t('Reward Claimed')}>
          <div className="point-use-wrapper">
            <p>{data?.amount}</p>
            <img
              alt="point-img"
              src={data?.token?.logo?.url || TICKET_TYPE_IMG.ticketToken}
              className="point-img"
            />
          </div>
        </td>
        <td data-label={t('filter.date')}>
          <p className="text-end">
            {format(
              parseISO(data?.updatedAt ? data?.updatedAt : new Date().toISOString()),
              'dd/MM/yyyy',
            )}
          </p>
        </td>
        <td data-label={t('redeemRewards.transaction')}>
          {data?.transactionHash ? (
            <div className="tx-hash-wrapper">
              <ShowTxHash
                type="tx"
                chainId={data?.chainId}
                txHash={data?.transactionHash}
                txLength={10}
              />
            </div>
          ) : (
            <RenderBtnResubmit data={data} getRedeemRewardHistory={getRedeemRewardHistory} />
          )}
        </td>
      </tr>
    </>
  );
};

export default RedeemHistoryTable;

function RenderStatusRedeem({ data }) {
  return (
    <div className="status-wrapper">
      <div className={`${data?.status === 'Claimed' ? 'green-dot' : 'red-dot'}`} />
      <p className={`${data?.status === 'Claimed' ? 'green-label' : 'red-label'}`}>
        {data?.status}
      </p>
    </div>
  );
}
