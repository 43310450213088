import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ASSETS_SIDE_BAR, PUBLIC_SIDE_BAR } from '../../../constants/sideBar';
import useScreenWidth from '../../../hooks/useScreenWidth';
import Board from './Board';
import './styles.scss';
import { useAuthContext } from '../../../context/AuthContext';
import { ICONS } from '../../../../assets/imgs';
import { DGPUB_DOCS_URL, DGPUB_FAQ_URL, TYPE_LANGUAGE_DROPDOWN } from '../../../constants';

function SideBar(props) {
  const { isShow, setShow } = props;
  const { isLogin, isCreatedProfile, isLinkedEmaiAddress } = useAuthContext();
  const { t } = useTranslation();

  const handleMouseMove = () => {
    $('#id-tooltip-explore-rental').slideDown();
    $('#id-tooltip-faq-rental').slideDown();
    $('#id-tooltip-blog-rental').slideDown();
    $('#id-tooltip-leaderboard-rental').slideDown();
    $('#id-tooltip-my-team-rental').slideDown();
    $('#id-tooltip-my-tournament-rental').slideDown();
    $('#id-tooltip-pending-team-rental').slideDown();
    $('#id-tooltip-my-reward').slideDown();
  };
  const handleMouseLeave = () => {
    $('#id-tooltip-explore-rental').stop(true, true).slideUp();
    $('#id-tooltip-faq-rental').stop(true, true).slideUp();
    $('#id-tooltip-blog-rental').stop(true, true).slideUp();
    $('#id-tooltip-leaderboard-rental').stop(true, true).slideUp();
    $('#id-tooltip-my-team-rental').stop(true, true).slideUp();
    $('#id-tooltip-my-tournament-rental').stop(true, true).slideUp();
    $('#id-tooltip-pending-team-rental').stop(true, true).slideUp();
    $('#id-tooltip-my-reward').stop(true, true).slideUp();
  };
  const screenWidth = useScreenWidth();
  useEffect(() => {
    if (screenWidth < 768) {
      if (isShow) {
        $('.side-bar').addClass('show-side-bar-content');
        handleMouseMove();
      } else {
        $('.side-bar').removeClass('show-side-bar-content');
        $('.side-bar').addClass('hide-side-bar-mobile');
        handleMouseLeave();
      }
    }
  }, [isShow, screenWidth]);

  return (
    <div
      className={`side-bar ${isShow || 'hide-side-bar'} ${
        isLogin && (!isCreatedProfile || !isLinkedEmaiAddress) ? 'disabled' : ''
      }`}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      aria-hidden
      ref={props.sideBarRef}
    >
      <div className="main-board">
        {PUBLIC_SIDE_BAR.map((item) => (
          <Board
            icon={item.icon}
            subIcon={item.subIcon}
            title={t(item.title)}
            path={item.path}
            imgStyles={item.imgStyles}
            id={item.id}
            key={item.id}
            setShow={setShow}
          />
        ))}
        {isLogin &&
          ASSETS_SIDE_BAR.map((item) => (
            <Board
              icon={item.icon}
              subIcon={item.subIcon}
              title={t(item.title)}
              path={item.path}
              imgStyles={item.imgStyles}
              id={item.id}
              key={item.id}
              setShow={setShow}
            />
          ))}
        <Board
          icon={ICONS.iconContact}
          subIcon={ICONS.iconContactSub}
          title={t('header.contactUs')}
          path="contact-us"
          imgStyles="board-icon-docs"
          id="id-tooltip-contact"
          key="id-tooltip-contact"
          setShow={setShow}
        />
        <Board
          icon={ICONS.iconFaq}
          subIcon={ICONS.iconFaqSub}
          title={t('header.faq')}
          path={DGPUB_FAQ_URL}
          imgStyles="board-icon-docs"
          id="id-tooltip-docs"
          key="id-tooltip-faq"
          setShow={setShow}
        />
        <Board
          icon={ICONS.iconDocActive}
          subIcon={ICONS.iconDoc}
          title={t('header.docs')}
          path={DGPUB_DOCS_URL}
          imgStyles="board-icon-docs"
          id="id-tooltip-docs"
          key="id-tooltip-docs"
          setShow={setShow}
        />
        {screenWidth < 768 && (
          <Board
            title={t('header.language')}
            imgStyles="board-icon-language"
            id="id-tooltip-language"
            key="id-tooltip-language"
            setShow={setShow}
            type={TYPE_LANGUAGE_DROPDOWN}
          />
        )}
      </div>
    </div>
  );
}

export default SideBar;
