import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TOURNAMENT } from '../../../../assets/imgs/index';
import { DATE_FORMAT, STATUS_TOURNAMENT_DETAIL } from '../../../constants';
import { BRACKETS_STRING } from '../../../constants/bracket';
import convertDate from '../../../utils/convertDate';
import './styles.scss';
import { getTranslatedValue } from '../../../lib/getTranslatedValue';
import GroupPeopleIcon from '../../icons/GroupPeopleIcon';
import ClockIcon from '../../icons/ClockIcon';
import BoxRewardIcon from '../../icons/BoxRewardIcon';
import TicketPrizeIcon from '../../icons/TicketPrizeIcon';

function CardTournament(props) {
  const { card } = props;
  const history = useHistory();

  const goToDetail = (slug) => {
    history.push(`/tournament/${slug}`);
  };
  const { t } = useTranslation();

  const renderDate = () => {
    let date;
    if (card.status === STATUS_TOURNAMENT_DETAIL.New) {
      date = card.registration_deadline;
    } else if (
      card.status === STATUS_TOURNAMENT_DETAIL.Done ||
      card.status === STATUS_TOURNAMENT_DETAIL.Live
    ) {
      date = card.end_date;
    }
    return convertDate(date, DATE_FORMAT.cardTournament);
  };

  const renderJoinFee = () => {
    if (card.ticketCollection) {
      return (
        <div>
          {card?.ticketCollection?.ticketType?.price || 0}&nbsp;
          {card?.ticketCollection?.paymentToken?.symbol}
        </div>
      );
    }
    return card.join_fee ? (
      <div>{`${card.join_fee || 0} ${card.applicationNetwork?.symbol}`}</div>
    ) : (
      <div>{t('feeToJoin')}</div>
    );
  };

  return (
    <div className="wrapper_game_card">
      <div className="wrap-game-card" onClick={() => goToDetail(card.slug)} aria-hidden>
        <div>
          <div className="wrap-card-tournament-image">
            <div className="crop-img">
              <img
                src={card?.image || TOURNAMENT.noImage}
                alt="dgpub-network"
                className="image-card-tournament"
              />
            </div>
            <div className="top-left-badge top-left-wrapper">
              {card?.status === STATUS_TOURNAMENT_DETAIL.Live && (
                <div className="wrap-abs-image common-info-highlight">
                  <div className="green-circle" />
                  <div className="live-text">{t('Live')}</div>
                </div>
              )}
              <div className="wrap-abs-image common-info-highlight">
                <GroupPeopleIcon />
                <div>
                  {card?.numberTeam}
                  {card?.applicationBracket !== BRACKETS_STRING.BATTLE_ROYALE &&
                    `/${card?.totalTeams ?? '-'}`}
                </div>
              </div>
              <div className="common-info-highlight wrap-abs-image">
                {card.applicationBracket ?? ''}
              </div>
            </div>
            <div className="chain-right-wrapper common-info-highlight">
              <img
                className="prize-pool-icon"
                src={card?.ticketCollection?.paymentToken?.chain?.image}
                alt="chain-logo"
              />
            </div>
          </div>

          <div className="wrap-card-tournament-body">
            <div className="info-row">
              <div className="title-game">{card?.applicationGame?.name}</div>
              <div className="title-host">{`${t('tournament.host')}: ${
                card?.applicationUser?.username || 'Admin'
              }`}</div>
            </div>
            <div className="info-row">
              <div className="title-tournament">{getTranslatedValue(card, 'name')}</div>
            </div>
            <div className="wrap-tournament-deadline">
              <ClockIcon />
              <div>{renderDate()}</div>
            </div>
            <div className="wrap-card-tournament-footer">
              <div className="wrap-tournament-prize">
                <BoxRewardIcon />
                {card.prize_amount || 0}&nbsp;{card?.gamePoint}
              </div>
              <div className="wrap-tournament-prize">
                <TicketPrizeIcon />
                {renderJoinFee()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardTournament;
