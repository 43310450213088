import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import ArrowDownIcon from '../../components/icons/ArrowDownIcon';
import PrimaryInputCheckbox from '../../components/common/Input/PrimaryInputCheckbox';
import {
  LIST_BRACKET_TOURNAMENT,
  LIST_STATUS_TOURNAMENT,
  LIST_TYPE_TOURNAMENT,
} from '../../constants';

function TournamentFilter({ filter, setFilter, listGame, handleClearFilter }) {
  const { t } = useTranslation();
  const LIST_CONDITION_FILTER = [
    {
      id: 1,
      title: 'Play mode',
      filterType: 'type',
      options: LIST_TYPE_TOURNAMENT,
      type: 'Checkbox',
    },
    {
      id: 2,
      title: 'Bracket',
      filterType: 'bracket',
      options: LIST_BRACKET_TOURNAMENT,
      type: 'Checkbox',
    },
    {
      id: 3,
      title: 'Status',
      filterType: 'status',
      options: LIST_STATUS_TOURNAMENT,
      type: 'Checkbox',
    },
    {
      id: 4,
      title: 'Game',
      filterType: 'gameId',
      options: listGame,
      type: 'Checkbox',
    },
  ];

  return (
    <div className="tournament_filter_desktop_wrapper">
      <div className="bg_color filter_title_wrapper">
        <div className="filter_title">{t('Filter')}</div>
        <div className="clear_title" onClick={handleClearFilter} aria-hidden="true">
          {t('Clear')}
        </div>
      </div>
      <div className="list_filters">
        {LIST_CONDITION_FILTER.map((item) => (
          <FilterDropdown
            key={`filter-${item?.id}`}
            filterCondition={item}
            filter={filter}
            setFilter={setFilter}
          />
        ))}
      </div>
    </div>
  );
}

export default TournamentFilter;

function FilterDropdown({ filterCondition, filter, setFilter }) {
  const [ispExpanded, setIspExpanded] = useState(true);
  const options = filterCondition?.options || [];
  const title = filterCondition?.title;
  const type = filterCondition?.type;
  const filterType = filterCondition?.filterType;
  const { t } = useTranslation();

  const handleInputCheckbox = (key) => {
    setFilter((prevFilter) => {
      const currentFilter = prevFilter[filterType] || [];
      if (currentFilter?.includes(key)) {
        return {
          ...prevFilter,
          [filterType]: currentFilter?.filter((item) => item !== key),
        };
      }
      return {
        ...prevFilter,
        [filterType]: [...currentFilter, key],
      };
    });
  };

  return (
    <div className="filter_item bg_color">
      <motion.div
        className="filter_title_wrapper cursor-pointer"
        onClick={() => setIspExpanded(!ispExpanded)}
      >
        <div className="title">{t(title)}</div>
        <div className={`${ispExpanded && 'rotate-180'} smooth-transform`}>
          <ArrowDownIcon />
        </div>
      </motion.div>

      <AnimatePresence initial>
        {ispExpanded && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: {
                opacity: 0,
                height: 0,
                animationDelay: '1',
              },
            }}
            transition={{
              duration: 0.4,
              ease: [0.04, 0.62, 0.73, 0.98],
            }}
            className="item_list"
          >
            {type === 'Checkbox' &&
              options.map((i) => (
                <PrimaryInputCheckbox
                  key={`${filterType}-${i?.id}`}
                  accessoriesRight={t(i?.name)}
                  value={i?.id}
                  onChange={() => handleInputCheckbox(i?.id)}
                  checked={filter[filterType]?.includes(i?.id)}
                />
              ))}
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
}
