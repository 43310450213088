import React from 'react';
import { ICONS } from '../../../../assets/imgs';
import InputField from '../Input';
import './styles.scss';

function SearchTable(props) {
  const { placeholder, search, setSearch, onKeyDown, handleSearchIconPress } = props;
  return (
    <div className="search-team">
      <InputField
        type="search"
        placeholder={placeholder}
        value={search}
        onChange={setSearch}
        onKeyDown={onKeyDown}
      />
      <img
        src={ICONS.iconSearch}
        alt="dgpub-network"
        className="icon-search"
        onClick={handleSearchIconPress}
        aria-hidden
      />
    </div>
  );
}

export default SearchTable;
