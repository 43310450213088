import FormData from 'form-data';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col } from 'reactstrap';
import { TEAM } from '../../../../assets/imgs';
import TButton from '../../../components/common/Button';
import NewLayout from '../../../components/common/NewLayout/NewLayout';
import SearchTable from '../../../components/common/Search/SearchTable';
import useMessage from '../../../components/common/toast/UseMessage';
import { ERR_MESSAGE, EVENT__KEY } from '../../../constants';
import tournamentApi from '../../../services/tournament';
import { useAppDispatch } from '../../../store';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import './styles.scss';

function MyTeamDetail(props) {
  const { dataConnect } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const dispatch = useAppDispatch();
  const [detail, setDetail] = useState(null);
  const [currentMember, setCurrentMember] = useState([]);
  const [numberMember, setNumberMember] = useState('');
  const [image, setImage] = useState(TEAM.logoTeamDefault);
  const { openMessageError } = useMessage();
  const { id } = useParams();
  const getDetail = async () => {
    dispatch(showLoading());
    try {
      const { data } = await tournamentApi.getMyTeamDetail(id);
      setDetail(data.data);
      setImage(data.data.avatar || TEAM.logoTeamDefault);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const getCurrentMember = async () => {
    dispatch(showLoading());
    const params = {
      name: search,
    };
    try {
      const { data } = await tournamentApi.getCurrentMember(params, id);
      setCurrentMember(data);
      if (!search) {
        setNumberMember(data.length);
      }
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleChangeProfileImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
      const dataForm = new FormData();
      dataForm.append('teamId', id);
      dataForm.append('image', e.target.files[0]);
      dispatch(showLoading());
      try {
        await tournamentApi.updateMyTeam(dataForm);
      } catch (error) {
        openMessageError(error.response.data?.message || ERR_MESSAGE);
      } finally {
        dispatch(hideLoading());
      }
    }
  };
  const isCheckImg = typeof image === 'string';
  useEffect(() => {
    getDetail();
    getCurrentMember();
  }, []);
  const history = useHistory();
  const goToUpdateDetail = () => {
    history.push(`/my-team/update/${id}`);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === EVENT__KEY.ENTER) {
      getCurrentMember();
    }
  };

  const resetCurrentMember = async () => {
    dispatch(showLoading());
    const params = {
      name: '',
    };
    try {
      const { data } = await tournamentApi.getCurrentMember(params, id);
      setCurrentMember(data);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleOnChangeSearch = (value) => {
    setSearch(value);
    if (!value) {
      resetCurrentMember();
    }
  };
  return (
    <NewLayout dataConnect={dataConnect} title={t('team.teamDetail')} type={1} link="/my-team">
      {detail && (
        <div className="team-detail">
          <div className="img-team">
            <div className="image-team mb-2">
              <img src={TEAM.bgTeam} alt="dgpub-network" />
              <div className="sky-mage">
                <div className="logo-no-text">
                  {detail.isOwner && (
                    <label htmlFor="ip-upload-image-team" className="bg-team">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleChangeProfileImage}
                        id="ip-upload-image-team"
                      />
                      <div className="btn-upload">{t('team.upload')}</div>
                    </label>
                  )}
                  {image && (
                    <img
                      src={isCheckImg ? image : window.URL.createObjectURL(image)}
                      alt="dgpub-network"
                      className="img-image-team"
                    />
                  )}
                </div>
                <h6>{detail.name}</h6>
              </div>
            </div>
          </div>
          <div className="info-team">
            <div>
              <div className="btn-info-detail member">
                {' '}
                {numberMember} {numberMember > 1 ? 'Members' : 'Member'}
              </div>
              <div className="btn-info-detail owner">
                {t('team.owner')}: {detail.owner}
              </div>
            </div>
            {detail.isOwner && (
              <TButton
                title={t('team.editTeam')}
                onClick={goToUpdateDetail}
                className="btn-update"
                type="reverse"
              />
            )}
          </div>
          <div className="list-member-team">
            <SearchTable
              placeholder={t('search.search')}
              search={search}
              setSearch={handleOnChangeSearch}
              onKeyDown={handleSearchKeyDown}
              handleSearchIconPress={getCurrentMember}
            />
            <div>
              <h6 className="title-member">{t('team.member')}</h6>
              {currentMember.length ? (
                <div className="wrap-list-participant">
                  {currentMember.map((member) => (
                    <Col key={member.id} className="wrap-item-participant">
                      <img
                        className="participant-image"
                        src={member.avatar ?? TEAM.avatarUser}
                        alt="img"
                      />
                      <div className="participant-name">{member.name}</div>
                    </Col>
                  ))}
                </div>
              ) : (
                <div className="no-text">{t('team.thereAreNoMember')}</div>
              )}
            </div>
          </div>
        </div>
      )}
    </NewLayout>
  );
}

export default MyTeamDetail;
