import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import Layout from '../../components/common/Layout';
import TournamentList from '../../components/pages/TournamentDetail/TournamentList';
import InfoTag from '../../components/pages/TournamentDetail/InfoTag';
import tournamentApi from '../../services/tournament';
import { useAppDispatch } from '../../store';
import {
  ERR_MESSAGE,
  DONATE_ERR_MESSAGE,
  DONATE_SUCCESS_MESSAGE,
  DATE_FORMAT,
  STATUS_JOIN_TOURNAMENT,
  STATUS_TOURNAMENT_DETAIL,
  JOIN_TOUR_SUCCESS_MESSAGE,
  JOIN_TOUR_ERR_MESSAGE,
  TYPE_TOURNAMENT,
  PRODUCT_ENV,
  APP_ENV_LIST,
  GAME_MEOW_NAME,
} from '../../constants';
import useMessage from '../../components/common/toast/UseMessage';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { TOURNAMENT } from '../../../assets/imgs';
import './styles.scss';
import ModalDonate from '../../components/common/Modal/ModalDonate';
import ModalJoinTournament from '../../components/pages/TournamentDetail/ModalJoinTournament';
import convertDate from '../../utils/convertDate';
import InfoBox from '../../components/pages/TournamentDetail/InfoBox/index';
import statusJoinTournament from '../../utils/statusJoinTournament';
import ModalPayTokenJoinTournament from '../../components/pages/TournamentDetail/ModalPayTokenJoinTournament';
import TButton from '../../components/common/Button';
import LoadingButton from '../../components/common/Button/LoadingButton';
import ModalBuyTicket from '../../components/pages/TournamentDetail/ModalBuyTicket';
import { BRACKETS_STRING } from '../../constants/bracket';
import ModalShare from '../../components/common/Modal/ModalShare';
import { toggleModalOpen } from '../../store/features/openModalLogin';
import { useAuthContext } from '../../context/AuthContext';
import useSwitchNetwork from '../../hooks/useSwitchNetwork';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import ModalConnectWallet from '../../components/common/ModalConnectWallet/ModalConnectWallet';
import shortenAddress from '../../utils/shortenAddress';
import ShareIcon from '../../components/icons/ShareIcon';
import { getTranslatedValue } from '../../lib/getTranslatedValue';

function TournamentDetail(props) {
  const { dataConnect } = props;
  const { t } = useTranslation();
  const { slug } = useParams();
  const dispatch = useAppDispatch();
  const { openMessageError, openMessage } = useMessage();
  const [upcomingTournaments, setUpcomingTournaments] = useState([]);
  const [tournament, setTournament] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [network, setNetwork] = useState();
  const [isJoin, setIsJoin] = useState(false);
  const [isPayToken, setIsPayToken] = useState(false);
  const [isBuyTicket, setIsBuyTicket] = useState(false);
  const [tournamentJoinTime, setTournamentJoinTime] = useState(null);
  const [dataBracket, setDataBracket] = useState();
  const [id, setId] = useState();
  const [showModalShare, setShowModalShare] = useState(false);
  const { isLogin, userEmail, walletAccount } = useAuthContext();
  const { switchNetwork } = useSwitchNetwork();
  const { provider: activeWeb3Provider, account: signerAddress } = useActiveWeb3React();
  const [showModalConnectWallet, setShowModalConnectWallet] = useState(false);

  async function getNumberOfTicket() {
    dispatch(showLoading());
    try {
      const { data } = await tournamentApi.getNumberOfTicket(tournament.ticketCollection?.address);
      if (Number(data?.remainingTurns) > 0) {
        return true;
      }
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
    return false;
  }

  useEffect(() => {
    if (tournament?.ticketCollection && Number(walletAccount)) {
      getNumberOfTicket().catch((error) => {
        openMessageError('Error: ', error);
      });
    }
  }, [tournament?.ticketCollection]);

  const handleConnectLinkWallet = () => {
    if (userEmail) {
      dataConnect.linkWallet();
    } else {
      dispatch(toggleModalOpen());
    }
  };
  const getUpcomingTournaments = async () => {
    dispatch(showLoading());
    const params = { status: STATUS_TOURNAMENT_DETAIL.Live, exclude_id: id };
    try {
      const res = await tournamentApi.getTournamentsByStatus(params);
      setUpcomingTournaments(res.data.tournaments);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const getCurrentTournament = async () => {
    dispatch(showLoading());
    try {
      const { data } = await tournamentApi.getTournamentBySlug(slug);
      setTournament(data);
      setId(data.id);
      setNetwork(data.applicationNetwork);
      const date = `Start at ${convertDate(data.start_date, DATE_FORMAT.cardTournament)}`;
      setTournamentJoinTime(date);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const getBrackets = async () => {
    dispatch(showLoading());
    try {
      const res = await tournamentApi.getBrackets(id);
      setDataBracket(res.data?.data);
    } catch (error) {
      openMessageError(error.response?.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (slug) {
      getCurrentTournament().catch((error) => {
        openMessageError('Error: ', error);
      });
    }
  }, [slug]);

  useEffect(() => {
    if (id) {
      getUpcomingTournaments().catch((error) => {
        openMessageError('Error: ', error);
      });
      setDataBracket(null);
      window.scroll(0, 0);
    }
  }, [id]);

  const showPopupDonate = () => {
    if (walletAccount) {
      setModalIsOpen(true);
    } else {
      dispatch(toggleModalOpen());
    }
  };

  const handleLinkWallet = () => {
    dataConnect.linkWallet();
  };

  const handlePayTicket = () => {
    window.open(`${GAME_MEOW_NAME.toLowerCase()}://`, '_blank', 'noopener,noreferrer');
  };

  const showModalJoin = (status) => {
    if (Number(status) === -1 && tournament?.applicationBracket !== BRACKETS_STRING.BATTLE_ROYALE) {
      setIsJoin(true);
    }
    async function check() {
      if (
        (tournament?.applicationBracket === BRACKETS_STRING.BATTLE_ROYALE &&
          tournament?.tournamentRequestStatus === STATUS_JOIN_TOURNAMENT.NOT_JOIN) ||
        Number(status) === 1 ||
        Number(status) === 5
      ) {
        if (tournament.ticketCollection) {
          const checkHaveTicket = await getNumberOfTicket().catch((error) => {
            openMessageError('Error: ', error);
          });
          if (checkHaveTicket) {
            handlePayTicket();
          } else {
            setIsBuyTicket(true);
          }
        } else {
          setIsPayToken(true);
        }
      }
    }
    check();
  };

  const sendDonate = async (numberToken, setNumberToken) => {
    try {
      dispatch(showLoading());
      await switchNetwork(network.chain_id);

      if (!signerAddress) {
        toast.error(t('require.connectyourWallet'));
        return;
      }
      if (walletAccount !== signerAddress) {
        toast.error(
          `${t('error.pleaseSwitch')} ${shortenAddress(walletAccount, 4)} ${t(
            'error.linkedAccount',
          )}`,
        );
        return;
      }

      await activeWeb3Provider.send('eth_requestAccounts', []);
      const signer = activeWeb3Provider.getSigner();
      const tx = {
        to: ethers.utils.getAddress(tournament.address),
        value: ethers.utils.parseEther(numberToken),
        gasPrice: await activeWeb3Provider.getGasPrice(),
      };
      const estimateGas = await activeWeb3Provider.estimateGas(tx);
      const txTransaction = await signer.sendTransaction({
        ...tx,
        gasLimit: estimateGas,
      });
      const res = await txTransaction.wait();
      const payload = {
        tournamentId: Number(id),
        amount: Number(numberToken),
        hashTransaction: res.transactionHash,
      };
      await tournamentApi.sendDonate(payload);
      openMessage({ message: DONATE_SUCCESS_MESSAGE });
      setNumberToken('');
    } catch (error) {
      openMessageError(DONATE_ERR_MESSAGE);
    } finally {
      setModalIsOpen(false);
      dispatch(hideLoading());
    }
  };

  const sendPayJoinTournament = async (numberToken) => {
    try {
      dispatch(showLoading());
      await switchNetwork(network.chain_id);

      if (!signerAddress) {
        toast.error(t('require.connectyourWallet'));
        return;
      }
      if (walletAccount !== signerAddress) {
        toast.error(
          `${t('error.pleaseSwitch')} ${shortenAddress(walletAccount, 4)} ${t(
            'error.linkedAccount',
          )}`,
        );
        return;
      }

      await activeWeb3Provider.send('eth_requestAccounts', []);
      const signer = activeWeb3Provider.getSigner();
      const tx = {
        to: ethers.utils.getAddress(tournament.address),
        value: ethers.utils.parseEther(numberToken),
        gasPrice: await activeWeb3Provider.getGasPrice(),
      };
      const estimateGas = await activeWeb3Provider.estimateGas(tx);
      const txTransaction = await signer.sendTransaction({
        ...tx,
        gasLimit: estimateGas,
      });
      const res = await txTransaction.wait();
      const payload = {
        tournamentId: Number(id),
        hashTransaction: res.transactionHash,
      };
      // api 404 error in dev
      await tournamentApi.sendCoinTournament(payload);
      setIsPayToken(false);
      getCurrentTournament().catch((error) => {
        openMessageError('Error: ', error);
      });
      openMessage({ message: JOIN_TOUR_SUCCESS_MESSAGE });
    } catch (error) {
      // eslint-disable-next-line
      console.log('Error: ', error);
      openMessageError(JOIN_TOUR_ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  function textTournamentDetail() {
    if (
      tournament?.applicationBracket === BRACKETS_STRING.BATTLE_ROYALE &&
      tournament?.tournamentRequestStatus === STATUS_JOIN_TOURNAMENT.NOT_JOIN
    ) {
      return t('JoinTournament');
    }

    if (tournament.status === STATUS_TOURNAMENT_DETAIL.Done) {
      const text = t('tournament.tournamentEnded');
      return text;
    }
    if (
      tournament.status === STATUS_TOURNAMENT_DETAIL.Live &&
      tournament?.applicationBracket !== BRACKETS_STRING.BATTLE_ROYALE
    ) {
      const liveTournament = `${t('liveAt')} ${convertDate(
        tournament.start_date,
        DATE_FORMAT.cardTournament,
      )}`;
      return liveTournament;
    }
    if (
      tournament.status === STATUS_TOURNAMENT_DETAIL.Live &&
      tournament?.applicationBracket === BRACKETS_STRING.BATTLE_ROYALE &&
      tournament?.tournamentRequestStatus === STATUS_JOIN_TOURNAMENT.PAID
    ) {
      const text = t('readyToPlay');
      return text;
    }
    if (tournament.status === STATUS_TOURNAMENT_DETAIL.New) {
      if (STATUS_JOIN_TOURNAMENT.PAID === tournament.tournamentRequestStatus) {
        return tournamentJoinTime;
      }
      return statusJoinTournament(tournament.tournamentRequestStatus).name;
    }
    return '';
  }

  const renderBtnJoinTour = () => {
    if (!isLogin) {
      return (
        <TButton
          title={t('require.logIn')}
          onClick={() => {
            dispatch(toggleModalOpen());
          }}
          className="btn-join"
        />
      );
    }
    if (!signerAddress) {
      return (
        <>
          <TButton
            title={t('login.connectWallet')}
            className="btn-join"
            onClick={() => setShowModalConnectWallet(true)}
          />
          <ModalConnectWallet
            showDialog={showModalConnectWallet}
            setShowDialog={setShowModalConnectWallet}
          />
        </>
      );
    }
    if (isLogin && !Number(walletAccount) && userEmail) {
      return (
        <TButton title={t('require.linkWallet')} onClick={handleLinkWallet} className="btn-join" />
      );
    }
    return Number(walletAccount) ? (
      <LoadingButton
        title={textTournamentDetail()}
        className={`btn-tournament btn-join ${
          statusJoinTournament(tournament.tournamentRequestStatus).style
        }`}
        disabled={
          (tournament.status === STATUS_TOURNAMENT_DETAIL.Live && tournament.type !== 1) ||
          tournament.status === STATUS_TOURNAMENT_DETAIL.Done ||
          (tournament.status === STATUS_TOURNAMENT_DETAIL.Live &&
            tournament?.applicationBracket === BRACKETS_STRING.BATTLE_ROYALE &&
            tournament?.tournamentRequestStatus === STATUS_JOIN_TOURNAMENT.PAID) ||
          (tournament?.status === STATUS_TOURNAMENT_DETAIL.Live &&
            tournament?.type === TYPE_TOURNAMENT.INSTANT &&
            PRODUCT_ENV === APP_ENV_LIST.PRODUCTION)
        }
        onClick={() => showModalJoin(tournament.tournamentRequestStatus)}
        loading={false}
      />
    ) : (
      <TButton
        title={isLogin ? t('require.linkWallet') : t('require.logIn')}
        onClick={handleConnectLinkWallet}
        className="btn-join"
      />
    );
  };

  return (
    <Layout
      title={getTranslatedValue(tournament, 'name')}
      dataConnect={dataConnect}
      type={1}
      link="/tournament"
    >
      <ModalJoinTournament
        show={isJoin}
        setShow={setIsJoin}
        registrationForm={tournament.registrationForm}
        teamSize={tournament?.applicationMode?.teamSize || '-'}
        maxTeamSize={tournament?.applicationMode?.maxTeamSize || '-'}
        getCurrentTournament={getCurrentTournament}
        id={id}
      />
      <div className="tournament-detail">
        <ModalDonate
          show={modalIsOpen}
          setShow={setModalIsOpen}
          sendDonate={sendDonate}
          symbolToken={network?.symbol}
          id={id}
        />
        <ModalPayTokenJoinTournament
          show={isPayToken}
          setShow={setIsPayToken}
          joinFee={tournament.join_fee || 0}
          symbolToken={network?.symbol}
          sendDonate={sendPayJoinTournament}
        />
        <ModalBuyTicket
          show={isBuyTicket}
          setShow={setIsBuyTicket}
          collectionId={tournament.ticketCollection?.id}
          collectionSlug={tournament.ticketCollection?.slug}
        />

        {tournament && (
          <Row className="mb-5">
            <Col lg="6" md="12" sm="12" className="wrap-image-area">
              <div className="main-image">
                <img src={tournament.image ?? TOURNAMENT.noImage} alt="dgpub-network" />
              </div>
            </Col>
            <Col lg="6" md="12" sm="12" className="wrap-right">
              <h4 className="tournament-name">{getTranslatedValue(tournament, 'name')}</h4>
              <div className="tournament-creator-wrap">
                <div className="tournament-name-wrap">
                  <h5 className="info-title">{t('donate.game')}</h5>
                  <h4 className="info-content">{tournament.applicationGame?.name}</h4>
                </div>
                <div className="tournament-name-wrap">
                  <h5 className="info-title">{t('donate.createdBy')}</h5>
                  <h4 className="info-content info-created">
                    {tournament.applicationUser?.username ?? ''}
                  </h4>
                </div>
                <div className="tournament-name-wrap">
                  <h5 className="info-title">{t('Chain')}</h5>
                  <h4 className="info-content">
                    {tournament.applicationNetwork?.network_name ?? ''}
                  </h4>
                </div>
              </div>
              <div className="break-line" />
              <div className="info-tag">
                <InfoTag tournament={tournament} />
              </div>
              <div className="btn-tournament-detail">
                {renderBtnJoinTour()}
                <div className="wrap-btn">
                  <TButton
                    title={t('donate.default')}
                    onClick={showPopupDonate}
                    type="reverse"
                    className="btn-donate"
                    disabled={tournament.status === STATUS_TOURNAMENT_DETAIL.Done}
                  />
                  <div>
                    <ModalShare show={showModalShare} setShow={setShowModalShare} slug={slug} />
                    <TButton
                      id="share-tournament-btn"
                      type="reverse"
                      className="btn-share"
                      onClick={() => {
                        setShowModalShare(true);
                      }}
                    >
                      <ShareIcon />
                    </TButton>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <InfoBox
          tournament={tournament}
          bracket={tournament?.bracket}
          id={id}
          dataBracket={dataBracket}
          getBrackets={getBrackets}
        />

        <div className="upcoming-tournament-wrap">
          <h2 className="title-upcoming-tournament">{t('tournament.liveTournaments')}</h2>
          <Row>
            {upcomingTournaments && (
              <TournamentList tournaments={upcomingTournaments} type="upcoming" />
            )}
          </Row>
        </div>
      </div>
    </Layout>
  );
}

export default TournamentDetail;
