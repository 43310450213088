import { SupportedChainId } from './index';

export const CONTRACT_BY_CHAIN = {
  [SupportedChainId.ANCIENT8_TESTNET]: {
    tournamentAddress: '0x6fc99b5B1213b08833cD351a2642e7f88969622b',
  },
  [SupportedChainId.ANCIENT8_MAINNET]: {
    tournamentAddress: '0xD4da44aF733495f6be2CcC1f2F3881EFc704D084',
  },
};
