import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import SquareCloseDialogIcon from '../../icons/SquareCloseDialogIcon';
import { REDEEM_IMG } from '../../../../assets/imgs';
import TButton from '../../common/Button';
import { MARKETPLACE_FRONTEND_URL } from '../../../constants';

function ModalRedeemSuccess(props) {
  const { show, setShow, redeemRequest } = props;
  const { t } = useTranslation();
  const redeemSuccessRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!redeemSuccessRef?.current?.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [redeemSuccessRef]);

  const handleExchangeTicket = () => {
    const exchangeTicketUrl = `${MARKETPLACE_FRONTEND_URL}profile?tab=my-nfts&address=${
      redeemRequest?.tokenAddress
    }&quantity=${redeemRequest?.amount}&dg_access_token=${Cookies.get('token')}`;
    window.open(exchangeTicketUrl, '_blank');
  };

  const handleDepositTicket = () => {
    const depositTicketUrl = `${MARKETPLACE_FRONTEND_URL}profile?tab=my-tickets&dg_access_token=${Cookies.get(
      'token',
    )}`;
    window.open(depositTicketUrl, '_blank');
  };

  return (
    <div
      ref={redeemSuccessRef}
      className={
        show
          ? 'modal-redeem-success show-modal-redeem-success redeem-success-modal'
          : 'modal-redeem-success'
      }
    >
      <div className="modal-header">
        <div
          className="cursor-pointer"
          onClick={() => {
            setShow(false);
          }}
          aria-hidden
        >
          <SquareCloseDialogIcon />
        </div>
      </div>
      <div className="redeem-body">
        <img
          src={REDEEM_IMG.redeemSuccess}
          alt="dgpub-redeem-success"
          className="img-redeem-success"
        />
        <div className="success-title">{t('Congratulations!')}</div>
        <div className="success-description">
          {t('Exchange your tickets on Marketplace to get crypto (USDT) or keep playing game!')}
        </div>
        <TButton
          title={t('Exchange Tickets')}
          className="btn-exchange-ticket"
          onClick={handleExchangeTicket}
        />
        <div className="btn-deposit-ticket" onClick={handleDepositTicket} aria-hidden>
          {t('Deposit tickets')}
        </div>
      </div>
    </div>
  );
}

export default ModalRedeemSuccess;
