import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import { TEAM, IMG_PRIZES } from '../../../../../assets/imgs';
import { PRIZES } from '../../../../constants';

import './styles.scss';

function TopWinner(props) {
  const { prize, token, prizeType, type } = props;
  const [prizeImage, setPrizeImage] = useState('');

  useEffect(() => {
    switch (prizeType) {
      case PRIZES.FIRST_PRIZE:
        setPrizeImage(IMG_PRIZES.firstPrize);
        break;
      case PRIZES.SECOND_PRIZE:
        setPrizeImage(IMG_PRIZES.secondPrize);
        break;
      case PRIZES.THIRD_PRIZE:
        setPrizeImage(IMG_PRIZES.thirdPrize);
        break;
      default:
    }
  }, []);

  return (
    <Col className="prize-wrapper" lg="3" md="8">
      <div className="prize-info padding-justify">
        <div className="prize-team-img-wrap">
          <img src={prizeImage} alt="img-prize" />
          {type === 'winner' && (
            <img
              className="prize-team-img"
              src={prize.applicationTeam?.avatar || TEAM.logoTeamDefault}
              alt="dgpub-network"
            />
          )}
        </div>
        {type === 'winner' && (
          <h3 className="prize-team-name">{prize.applicationTeam?.name ?? '-'}</h3>
        )}
        <h4 className="prize-name">{prize.name ?? '-'}</h4>
        <p className="prize-value">
          {prize.prize} {token}
        </p>
      </div>
    </Col>
  );
}

export default TopWinner;
