import React from 'react';
import { useTranslation } from 'react-i18next';
import TButton from '../Button';
import './styles.scss';
import CloseDialogIcon from '../../icons/CloseDialogIcon';
import { useAuthContext } from '../../../context/AuthContext';

function WrongWalletWarningPopup(props) {
  const { show, setShow, linkedWallet = '', injectedAccount = '' } = props;
  const { t } = useTranslation();

  const { handleLogOut } = useAuthContext();
  const closeWarningPopup = () => {
    localStorage.setItem('isOpenedWalletWarning', '1');
    setShow(false);
  };
  const handleLoginAgain = () => {
    setShow(false);
    handleLogOut();
  };

  return (
    <div className="modal-wrapper-gray">
      <div className={show ? 'wrap-modal-success wrap-modal-shadow d-block' : 'wrap-modal-success'}>
        <div className="group-header">
          <h6 className="color-primary mb-2" style={{ fontSize: '24px' }}>
            {t('alert.warning.title')}
          </h6>
          <CloseDialogIcon onClick={closeWarningPopup} className="cursor-pointer" />
        </div>

        <div className="warning-message">
          {t('alert.warning.description1')}&nbsp;
          <span className="color-primary">{linkedWallet}</span>
          {t('alert.warning.description2')}&nbsp;
          <span className="color-primary">{injectedAccount}</span>
          {t('alert.warning.description3')}&nbsp;
          <span className="text-subBlue">app.dg.pub.</span> {t('alert.warning.description4')}
        </div>

        <div className="btn-wrapper-warning">
          <TButton onClick={closeWarningPopup} className="secondary-btn">
            {t('alert.reject')}
          </TButton>
          <TButton onClick={handleLoginAgain} className="login-btn">
            {t('alert.loginAgain')}
          </TButton>
        </div>
      </div>
    </div>
  );
}

export default WrongWalletWarningPopup;
