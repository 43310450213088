import React from 'react';
import { useTranslation } from 'react-i18next';
import TButton from '../../components/common/Button';

function SocialProfileItem({
  icon,
  title = '',
  defaultText = '',
  buttonTitle = '',
  handleClickLinkAccount,
}) {
  const { t } = useTranslation();
  return (
    <div className="social-item-wrapper">
      <div className="icon-wrapper">
        {icon}
        {title ? (
          <div className="icon-title">{title}</div>
        ) : (
          <div className="txt-icon-default">{t(defaultText)}</div>
        )}
      </div>
      {title ? (
        <div className="text-title-linked">{t('Linked')}</div>
      ) : (
        <TButton className="custom-btn-link" onClick={handleClickLinkAccount}>
          {t(buttonTitle)}
        </TButton>
      )}
    </div>
  );
}
export default SocialProfileItem;
