import React from 'react';

function GroupPeopleIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.95 14.54c-3.45 0-6.362.564-6.362 2.74S8.518 20 11.95 20c3.45 0 6.361-.564 6.361-2.74s-2.93-2.72-6.36-2.72"
        fill="#fff"
      />
      <path
        opacity={0.4}
        d="M11.949 12.467a4.207 4.207 0 004.21-4.233A4.206 4.206 0 0011.948 4a4.206 4.206 0 00-4.21 4.234 4.207 4.207 0 004.21 4.233M21.088 9.22c.604-2.378-1.168-4.513-3.424-4.513-.245 0-.48.027-.71.072-.03.008-.064.023-.082.05-.02.034-.005.08.017.11a5.807 5.807 0 011.068 3.37 5.762 5.762 0 01-.984 3.242.158.158 0 00.106.244c.158.028.319.042.484.047 1.643.043 3.117-1.02 3.525-2.623"
        fill="#fff"
      />
      <path
        d="M22.81 14.817c-.302-.645-1.028-1.087-2.132-1.304-.52-.128-1.931-.308-3.243-.284-.02.003-.03.017-.032.026-.003.012.003.034.029.047.606.302 2.949 1.614 2.654 4.381-.012.12.084.224.203.206.576-.083 2.06-.404 2.52-1.402a1.922 1.922 0 000-1.67"
        fill="#fff"
      />
      <path
        opacity={0.4}
        d="M7.045 4.78a3.514 3.514 0 00-.71-.073c-2.256 0-4.028 2.135-3.422 4.512.406 1.603 1.88 2.666 3.524 2.623.165-.005.327-.02.483-.047a.158.158 0 00.107-.244 5.764 5.764 0 01-.984-3.241c0-1.251.39-2.415 1.068-3.37.022-.03.038-.077.016-.11-.018-.029-.05-.043-.082-.05"
        fill="#fff"
      />
      <path
        d="M3.322 13.513c-1.104.217-1.83.659-2.13 1.304a1.918 1.918 0 000 1.67c.46.998 1.943 1.32 2.52 1.402.119.018.214-.085.201-.206-.294-2.766 2.05-4.078 2.656-4.38.025-.014.03-.035.028-.049-.002-.009-.012-.022-.031-.024-1.313-.025-2.722.155-3.244.283"
        fill="#fff"
      />
    </svg>
  );
}

export default GroupPeopleIcon;
