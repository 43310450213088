import React, { useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import useWalletConnect from '../../../hooks/useWalletConnect';
import { COPY_TIME_INTERVAL } from '../../../constants';
import { ConnectorNames } from '../../../lib/connectors';
import CloseDialogIcon from '../../icons/CloseDialogIcon';
import { HOME, QR_CODE_IMG } from '../../../../assets/imgs';

function ModalScanQrDggWallet(props) {
  const { showDialog, setShowDialog } = props;
  const loginRef = useRef();
  const { t } = useTranslation();
  const { uri, getUri, clearUri } = useWalletConnect();
  const [copiedText, setCopiedText] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!loginRef.current.contains(event.target)) {
        setShowDialog(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [loginRef]);

  useEffect(() => {
    const resetState = setInterval(() => {
      setCopiedText(false);
    }, COPY_TIME_INTERVAL);
    return () => clearInterval(resetState);
  }, []);

  useEffect(() => {
    getUri();
    if (showDialog) {
      localStorage.setItem('connector', ConnectorNames.DgPubWalletDesktop);
    }
    return () => {
      clearUri();
    };
  }, []);

  return (
    <div
      style={{ zIndex: '1000' }}
      ref={loginRef}
      className={
        showDialog ? 'wrap-modal-custom model-scan-qr-wallet d-block' : 'wrap-modal-custom'
      }
    >
      <div className="motion-dialog">
        <div className="scan-dgg-wallet-wrapper">
          <div className="header-connect-wrapper">
            <div className="header-connect">
              <img alt="dgpub" className="connect-icon" src={HOME.logoNoText} />
              <p className="connect-name">{t('wallet.defaut')}</p>
            </div>
            <div
              aria-hidden="true"
              className="close-dialog cursor-pointer"
              onClick={() => setShowDialog(false)}
            >
              <CloseDialogIcon />
            </div>
          </div>
          <div className="qr-code-wrapper">
            <p className="title-qrcode">{t('login.withWallet.qrCode')}</p>
            <div className="">
              {!uri && <div className="skeleton-uri skeleton-loading" />}
              {uri && (
                <QRCodeSVG
                  size={440}
                  imageSettings={{
                    src: QR_CODE_IMG.dgPubWallet,
                    height: 40,
                    width: 40,
                    excavate: true,
                  }}
                  bgColor="#ffffff"
                  className="aspect-square"
                  value={uri}
                  includeMargin
                />
              )}
            </div>
            <CopyToClipboard onCopy={() => setCopiedText(true)} text={uri}>
              <div className="text-copy cursor-pointer">
                {copiedText ? t('copy.copied') : t('copy.copyToClipboard')}
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalScanQrDggWallet;
