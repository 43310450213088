import React from 'react';

function GoogleIcon(props) {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.52 12.773c0-.851-.076-1.67-.218-2.455H12v4.642h6.458a5.52 5.52 0 01-2.394 3.622v3.01h3.878c2.269-2.088 3.578-5.165 3.578-8.82z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24.5c3.24 0 5.956-1.075 7.942-2.907l-3.878-3.011c-1.075.72-2.45 1.145-4.064 1.145-3.125 0-5.77-2.11-6.715-4.947H1.276v3.11A11.995 11.995 0 0012 24.5z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.285 14.78a7.213 7.213 0 01-.376-2.28c0-.79.136-1.56.376-2.28V7.111H1.276A11.995 11.995 0 000 12.501c0 1.936.464 3.768 1.276 5.388l4.01-3.109z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.273c1.762 0 3.344.605 4.587 1.794l3.442-3.442C17.951 1.69 15.235.5 12 .5 7.31.5 3.25 3.19 1.276 7.11l4.01 3.11C6.228 7.384 8.874 5.273 12 5.273z"
        fill="#EA4335"
      />
    </svg>
  );
}

export default GoogleIcon;
