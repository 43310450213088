import React from 'react';

function GamePad(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M16.321 5.348c2.47-.977 5.28.22 6.258 2.69.978 2.469 2.47 10.023-.684 11.27-1.736.684-3.765-.978-5.403-3.007-.587-.758-1.491-1.174-2.444-1.174h-2.494c-.953 0-1.858.44-2.445 1.174-1.638 2.053-3.667 3.691-5.402 3.007-3.154-1.247-1.663-8.801-.685-11.27a4.831 4.831 0 016.258-2.69c.343.147.685.318.978.538.367.27.758.465 1.198.611.44.147.88.22 1.345.22.904 0 1.784-.317 2.542-.83.294-.22.636-.392.978-.539z"
        fill="#fff"
      />
      <rect x={6.79688} y={8.0625} width={1.34147} height={5.36588} rx={0.670735} fill="#fff" />
      <rect
        x={10.1562}
        y={10.0742}
        width={1.34147}
        height={5.36588}
        rx={0.670736}
        transform="rotate(90 10.156 10.074)"
        fill="#fff"
      />
      <ellipse cx={16.5246} cy={10.7473} rx={0.903521} ry={0.903521} fill="#fff" />
      <circle cx={19.9191} cy={10.7473} r={0.903521} fill="#fff" />
      <circle cx={18.2199} cy={8.96602} r={0.903521} fill="#fff" />
      <circle cx={18.2199} cy={12.5246} r={0.903521} fill="#fff" />
    </svg>
  );
}

export default GamePad;
