import React from 'react';

function YoutubeIcon({ color = '#00FFFF', ...props }) {
  return (
    <svg
      width={25}
      height={19}
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.107 5.486c-.163-1.582-.516-3.33-1.813-4.249C24.289.525 22.968.5 21.736.5c-2.607 0-5.214.004-7.82.006l-7.52.007c-1.048 0-2.065-.08-3.038.373-.835.39-1.489 1.13-1.882 1.953C.93 3.985.816 5.282.75 6.55a60.115 60.115 0 00.037 6.926c.107 1.682.377 3.541 1.677 4.614 1.153.95 2.78.996 4.274.998l14.236.011a17.02 17.02 0 001.863-.077c1.22-.132 2.382-.482 3.166-1.386.791-.911.995-2.18 1.115-3.38.292-2.915.29-5.857-.01-8.77zm-15.935 8.398v-8.17l7.073 4.084-7.073 4.086z"
        fill={color}
      />
    </svg>
  );
}

export default YoutubeIcon;
