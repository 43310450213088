import { initializeConnector } from '@web3-react/core';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { CHAINS } from './chains';

const [mainnet, ...optionalChains] = Object.keys(CHAINS).map(Number);

const DGPUB_PREFIX = 'dg://';

export const [walletConnectV2, hooks] = initializeConnector(
  (actions) =>
    new WalletConnect({
      actions,
      options: {
        projectId: process.env.WALLET_CONNECT_PROJECT_ID,
        chains: [mainnet],
        optionalChains: [mainnet, ...optionalChains],
        showQrModal: false,
        metadata: {
          name: 'DGPub - A Decentralized Gateway to Public',
          description: `DG Pub - Your gateway to Web3! Discover, learn, and connect with the decentralized world. Get guidance, resources, and support all in one user-friendly app. #${DGPUB_PREFIX}`,
          url: `https://app.dg.pub/#${DGPUB_PREFIX}`,
          icons: ['https://app.dg.pub/favicon.ico'],
        },
      },
    }),
);
