import React from 'react';

function SocialGoogleIcon(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={48} height={48} rx={8} fill="#002A42" />
      <rect
        x={0.25}
        y={0.25}
        width={47.5}
        height={47.5}
        rx={7.75}
        stroke="#000"
        strokeOpacity={0.15}
        strokeWidth={0.5}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.32 24.387c0-1.206-.108-2.365-.31-3.478H24v6.576h9.15c-.395 2.125-1.593 3.926-3.393 5.131v4.266h5.494c3.215-2.96 5.069-7.318 5.069-12.495z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 41c4.59 0 8.438-1.522 11.25-4.119l-5.493-4.265c-1.523 1.02-3.47 1.623-5.757 1.623-4.428 0-8.175-2.99-9.512-7.009h-5.68v4.404C11.605 37.19 17.355 41 24 41z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.488 27.23c-.34-1.02-.533-2.11-.533-3.23s.193-2.21.533-3.23v-4.404h-5.68A16.993 16.993 0 007 24c0 2.743.657 5.34 1.808 7.635l5.68-4.405z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 13.761c2.496 0 4.737.858 6.499 2.543l4.875-4.876C32.43 8.685 28.582 7 24 7c-6.645 0-12.395 3.81-15.192 9.366l5.68 4.404c1.337-4.018 5.084-7.009 9.512-7.009z"
        fill="#EA4335"
      />
    </svg>
  );
}

export default SocialGoogleIcon;
