import React from 'react';
import { useTranslation } from 'react-i18next';

function LoginEmailInput({
  className = '',
  title = 'Email',
  placeholder = 'dgpub@gmail.com',
  enableSendOtp,
  handleSendOtp,
  timeLeft,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <div className={`${className} login-email-input`}>
      <p className="title-input">{title}</p>
      <div className="input-wrapper">
        <input className="login-input" placeholder={placeholder} type="text" {...props} />
        {timeLeft ? (
          <p className="time-left">{timeLeft}s</p>
        ) : (
          <button
            type="button"
            onClick={handleSendOtp}
            disabled={!enableSendOtp}
            className={`${enableSendOtp ? 'enable-send-btn' : 'disable-send-btn'} new-btn-send-otp`}
          >
            {t('login.withEmail.OTP')}
          </button>
        )}
      </div>
    </div>
  );
}

export default LoginEmailInput;
