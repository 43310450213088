import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../components/common/Header/Header';
import HomeContent from '../components/pages/Home/HomeContent';
import './styles.scss';

function Home(props) {
  const { dataConnect } = props;
  const { t } = useTranslation();
  return (
    <div>
      <Header dataConnect={dataConnect} />
      <HomeContent />
      <footer className="footer-wrapper">
        <p className="title-footer">{t('footer.copyright')} ® 2024 DG Pub.</p>
      </footer>
    </div>
  );
}

export default Home;
