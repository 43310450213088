import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import SquareCloseDialogIcon from '../../icons/SquareCloseDialogIcon';
import RedeemStatusPanel from './RedeemStatusPanel';
import TButton from '../../common/Button';
import { COPY_TIME_INTERVAL, ClaimTicketSCStatus } from '../../../constants';
import WarningIcon from '../../icons/WarningIcon';
import VIcon from '../../icons/VIcon';
import CopyIcon from '../../icons/CopyIcon';
import shortenAddress from '../../../utils/shortenAddress';

function RedemptionModal(props) {
  const { t } = useTranslation();
  const [copiedText, setCopiedText] = useState(false);
  const {
    show,
    setShow,
    createRequestRedeemStatus,
    confirmRequestStatus,
    claimTicketStatus,
    handleClickRetryBtn,
    handleClickCloseBtn,
    handleResetStatus,
    showRetryBtn,
    chainSelected,
    tokenBalanceDetail,
  } = props;
  const shareRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (claimTicketStatus !== ClaimTicketSCStatus.CREATED) {
        return;
      }
      if (!shareRef.current.contains(event.target)) {
        setShow(false);
        handleResetStatus();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [shareRef, claimTicketStatus]);

  useEffect(() => {
    const resetState = setInterval(() => {
      setCopiedText(false);
    }, COPY_TIME_INTERVAL);
    return () => clearInterval(resetState);
  }, []);

  return (
    <div
      ref={shareRef}
      className={show ? 'modal-redeem show-modal-redeem redemption-modal' : 'modal-redeem'}
    >
      <div className="modal-header">
        <p className="header-title">{t('Claim rewards')}</p>
        <div
          className="cursor-pointer"
          onClick={() => {
            setShow(false);
            handleResetStatus();
            handleClickCloseBtn();
          }}
          aria-hidden
        >
          <SquareCloseDialogIcon />
        </div>
      </div>
      <div className="redeem-body">
        <div className="warning-wrapper">
          <WarningIcon width={20} height={18} />
          <p className="warning-title">{t('redeemRewards.require')}</p>
        </div>
        <div className="my-infor">
          <div className="infor-title">{t('redeemRewards.Network')}</div>
          <div className="infor-content">{chainSelected?.network_name}</div>
          <div className="infor-title">{t('redeemRewards.Contract')}</div>
          <CopyToClipboard
            onCopy={() => setCopiedText(true)}
            text={tokenBalanceDetail?.tokenAddress}
          >
            <div className="infor-content copy-content-wrapper">
              <p title={tokenBalanceDetail?.tokenAddress} className="copyable-text">
                {shortenAddress(tokenBalanceDetail?.tokenAddress, 6)}
              </p>
              <div className="copy-icon-wrapper">
                {copiedText ? <VIcon color="#00FFFF" /> : <CopyIcon color="#00FFFF" />}
              </div>
            </div>
          </CopyToClipboard>
          <div className="infor-title">{t('redeemRewards.Reward')}</div>
          <div className="infor-content">{`${tokenBalanceDetail?.balance} ${tokenBalanceDetail?.tokenSymbol}`}</div>
        </div>
        <RedeemStatusPanel
          createRequestRedeemStatus={createRequestRedeemStatus}
          confirmRequestStatus={confirmRequestStatus}
          claimTicketStatus={claimTicketStatus}
        />
      </div>
      <div className="btn-view-ticket-wrapper">
        {showRetryBtn && (
          <>
            <TButton
              title={t('alert.retry')}
              className="btn-retry-modal"
              onClick={handleClickRetryBtn}
            />
            <TButton title={t('Close')} className="btn-close-modal" onClick={handleClickCloseBtn} />
          </>
        )}
      </div>
    </div>
  );
}

export default RedemptionModal;
