import React from 'react';

function LoginProfileIcon(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.497 15.675c-4.313 0-7.997.68-7.997 3.4 0 2.72 3.66 3.425 7.997 3.425 4.313 0 7.997-.68 7.997-3.4 0-2.721-3.66-3.425-7.997-3.425z"
        fill="#003452"
      />
      <path
        opacity={0.4}
        d="M12.497 13.084a5.273 5.273 0 005.292-5.292A5.273 5.273 0 0012.497 2.5a5.274 5.274 0 00-5.292 5.292 5.274 5.274 0 005.292 5.292z"
        fill="#003452"
      />
    </svg>
  );
}

export default LoginProfileIcon;
