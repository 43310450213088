import React, { useState, useEffect } from 'react';
import './style.scss';
import Pagination from '../../common/Pagination/index';
import { useAppDispatch } from '../../../store';
import useMessage from '../../common/toast/UseMessage';
import tournamentApi from '../../../services/tournament';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import { ERR_MESSAGE } from '../../../constants';
import RedeemHistoryTable from '../../common/CommonTable/RedeemHistoryTable';

function HistoryRedeemTab() {
  const { openMessageError } = useMessage();
  const dispatch = useAppDispatch();
  const itemsPerPage = 10;
  const [totalPage, setTotalPage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [redeemHistory, setRedeemHistory] = useState([]);

  const getRedeemRewardHistory = async () => {
    dispatch(showLoading());
    const params = {
      page: currentPage,
      limit: itemsPerPage,
    };
    try {
      const res = await tournamentApi.getRedeemRequestHistory(params);
      setRedeemHistory(res?.data?.data);
      const totalItems = res?.data?.total;
      const totalPages = Math.ceil(totalItems / itemsPerPage);
      setTotalPage(totalPages);
    } catch (error) {
      openMessageError(error.response?.data || error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getRedeemRewardHistory();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="redeem-history-wrapper">
      <div className="reward-history-table redeem-history-table">
        <RedeemHistoryTable data={redeemHistory} getRedeemRewardHistory={getRedeemRewardHistory} />
        <div className="reward-pagination">
          <Pagination totalPage={totalPage} onChange={handlePageChange} forcePage={currentPage} />
        </div>
      </div>
    </div>
  );
}

export default HistoryRedeemTab;
