import React from 'react';
import Helmet from 'react-helmet';
import { GTAG_KEY } from '../../../constants';

export default function Seo() {
  const title = 'DG Pub Game Platform';
  // eslint-disable-next-line
  const description =
    'DG Pub Game Platform is the ultimate destination for game tournaments, where gamers play to achieve rewards and access exciting features for competitive play.';
  return (
    <Helmet title={title}>
      <meta charSet="utf-8" />
      <html lang="en" />
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content="https://s3.ap-southeast-1.amazonaws.com/static-dg-pub-prod/1719393009340.png"
      />

      <script async src={`https://www.googletagmanager.com/gtag/js?id=G-${GTAG_KEY}`} />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-${GTAG_KEY}');
        `}
      </script>
    </Helmet>
  );
}
