import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TOURNAMENT } from '../../../assets/imgs/index';
import Layout from '../../components/common/Layout';
import NavBar from '../../components/common/NavBar';
import useMessage from '../../components/common/toast/UseMessage';
import InStatusTournament from '../../components/pages/Tournament/InStatusTournament';
import { ERR_MESSAGE, STATUS_TOURNAMENT_DETAIL, TYPE_TOURNAMENT } from '../../constants';
import tournamentApi from '../../services/tournament';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import './styles.scss';
import InstantThumbnailTournament from '../../components/pages/Tournament/InstantThumbnailTournament';
import InstantCardTournament from '../../components/pages/Tournament/InstantCardTournament';

function Tournaments(props) {
  const { t } = useTranslation();
  const { dataConnect } = props;
  const [upcomingTournaments, setUpcomingTournaments] = useState([]);
  const [inProgressTournaments, setInProgressTournaments] = useState([]);
  const [completedTournaments, setCompletedTournaments] = useState([]);
  const [instantTournaments, setInstantTournaments] = useState([]);
  const { openMessageError } = useMessage();
  const dispatch = useAppDispatch();

  const liveTournamentParams = { page: 1, limit: 10, status: STATUS_TOURNAMENT_DETAIL.Live };
  const newTournamentParams = { page: 1, limit: 10, status: STATUS_TOURNAMENT_DETAIL.New };
  const pastTournamentParams = { page: 1, limit: 10, status: STATUS_TOURNAMENT_DETAIL.Done };
  const instantTournamentParams = {
    page: 1,
    limit: 10,
    type: TYPE_TOURNAMENT.INSTANT,
    status: STATUS_TOURNAMENT_DETAIL.Live,
  };

  const getUpcomingTournaments = async () => {
    dispatch(showLoading());
    try {
      const res = await tournamentApi.getTournamentsByStatus(newTournamentParams);
      setUpcomingTournaments(res.data.tournaments);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const getInProgressTournaments = async () => {
    dispatch(showLoading());
    try {
      const res = await tournamentApi.getTournamentsByStatus(liveTournamentParams);
      setInProgressTournaments(res.data.tournaments);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const getCompletedTournaments = async () => {
    dispatch(showLoading());
    try {
      const res = await tournamentApi.getTournamentsByStatus(pastTournamentParams);
      setCompletedTournaments(res.data.tournaments);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const getInstantTournaments = async () => {
    dispatch(showLoading());
    try {
      const res = await tournamentApi.getTournamentsByStatus(instantTournamentParams);
      setInstantTournaments(res.data.tournaments);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getUpcomingTournaments();
    getInProgressTournaments();
    getCompletedTournaments();
    getInstantTournaments();
  }, []);

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <Layout dataConnect={dataConnect}>
      <div className="wrap-tournament">
        {instantTournaments?.length > 0 ? (
          <div className="instant-tournament">
            <Slider
              asNavFor={nav2}
              ref={(slider1) => setNav1(slider1)}
              arrows={false}
              slidesToScroll={1}
              slidesToShow={1}
              className="thumbnail-slick"
              pauseOnHover
              autoplay
              autoplaySpeed={5000}
              beforeChange={(_, nextSlide) => {
                setActiveSlide(nextSlide);
              }}
              dots
              appendDots={(dots) => <ul>{dots}</ul>}
              customPaging={() => (
                <div className="ft-slick__dots--custom">
                  <div className="loading" />
                </div>
              )}
            >
              {instantTournaments?.map((item) => (
                <div key={item.id}>
                  <InstantThumbnailTournament data={item} />
                </div>
              ))}
            </Slider>
            <Slider
              asNavFor={nav1}
              ref={(slider2) => setNav2(slider2)}
              slidesToShow={8}
              swipeToSlide
              focusOnSelect
              arrows={false}
              className="right-slick"
              pauseOnHover
              infinite={instantTournaments?.length > 7}
            >
              {instantTournaments?.map((i, index) => (
                <InstantCardTournament key={i?.id} data={i} activeSlide={activeSlide === index} />
              ))}
            </Slider>
          </div>
        ) : (
          <div className="instant-banner-wrapper">
            <img src={TOURNAMENT.instantBanner} className="instant-banner" alt="instant-banner" />
          </div>
        )}
        <NavBar />
        {upcomingTournaments?.length > 0 && (
          <div className="wrap-tournament-container wrap-upcoming-tournament mb-5">
            <Row className="wrap-container-tournament-title">
              <Col className="title" xs="12" sm="9" md="10">
                <h1>{t('tournament.newTournament')}</h1>
              </Col>
            </Row>
            <Row>
              <InStatusTournament data={upcomingTournaments} />
            </Row>
          </div>
        )}
        {inProgressTournaments?.length > 0 && (
          <div className="wrap-tournament-container wrap-upcoming-tournament mb-5">
            <Row className="wrap-container-tournament-title">
              <Col className="title" xs="12" sm="9" md="10">
                <h1>{t('tournament.liveTournaments')}</h1>
              </Col>
            </Row>
            <Row>
              <InStatusTournament data={inProgressTournaments} />
            </Row>
          </div>
        )}
        {completedTournaments?.length > 0 && (
          <div className="wrap-tournament-container wrap-completed-tournament mb-5">
            <Row className="wrap-container-tournament-title">
              <Col className="title" xs="12" sm="9" md="10">
                <h1>{t('tournament.doneTournaments')}</h1>
              </Col>
            </Row>
            <Row>
              <InStatusTournament data={completedTournaments} />
            </Row>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default Tournaments;
