import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import JSCookie from 'js-cookie';
import { setCheckLogin } from '../api/authFunctions';

const AuthContext = createContext({
  isLogin: false,
  userEmail: '',
  isCreatedProfile: true,
  isLinkedEmaiAddress: true,
  walletAccount: '',
  isOpenModalLogin: false,
  checkLogin: () => null,
  checkCreatedProfile: () => null,
  checkLinkedEmailAddress: () => null,
  toggleModalLoginOpen: () => null,
  toggleModalLoginClose: () => null,
  handleLogOut: () => null,
});

function AuthProvier({ children }) {
  const [isCreatedProfile, setIsCreatedProfile] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [isOpenModalLogin, setIsOpenModalLogin] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [isLinkedEmaiAddress, setIsLinkedEmaiAddress] = useState(true);
  const [walletAccount, setWalletAccount] = useState('');

  const { connector } = useWeb3React();

  const checkLogin = () => {
    const token = JSCookie.get('token');
    if (token) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  };

  const checkCreatedProfile = () => {
    const isCreatedProfile = localStorage.getItem('isCreateProfile');
    if (isCreatedProfile === 'false') {
      setIsCreatedProfile(false);
    } else {
      setIsCreatedProfile(true);
    }
  };

  const checkLinkedEmailAddress = () => {
    const isLinkedEmaiAddress = localStorage.getItem('isLinkedWithEmaiAddress');
    const email = localStorage.getItem('email');
    const walletAccount = localStorage.getItem('walletAccount');
    setWalletAccount(walletAccount);
    setUserEmail(email);
    if (isLinkedEmaiAddress === 'false') {
      setIsLinkedEmaiAddress(false);
    } else {
      setIsLinkedEmaiAddress(true);
    }
  };

  const toggleModalLoginOpen = () => {
    setIsOpenModalLogin(true);
  };

  const toggleModalLoginClose = () => {
    setIsOpenModalLogin(false);
  };

  const handleLogOut = () => {
    JSCookie.remove('token');
    JSCookie.remove('refreshToken');
    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
    localStorage.clear();
    checkLogin();
    checkCreatedProfile();
    checkLinkedEmailAddress();
  };

  useEffect(() => {
    checkLogin();
    checkCreatedProfile();
    checkLinkedEmailAddress();
    setCheckLogin(checkLogin);
  }, []);

  const contextValue = useMemo(
    () => ({
      isLogin,
      userEmail,
      isCreatedProfile,
      isLinkedEmaiAddress,
      walletAccount,
      isOpenModalLogin,
      handleLogOut,
      toggleModalLoginOpen,
      toggleModalLoginClose,
      checkLogin,
      checkCreatedProfile,
      checkLinkedEmailAddress,
    }),
    [isLogin, isCreatedProfile, isLinkedEmaiAddress, isOpenModalLogin],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

const useAuthContext = () => useContext(AuthContext);

export { AuthContext, AuthProvier, useAuthContext };
