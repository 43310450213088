import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TEAM } from '../../../../../assets/imgs';
import { ERR_MESSAGE, EVENT__KEY } from '../../../../constants';
import tournamentApi from '../../../../services/tournament';
import { useAppDispatch } from '../../../../store';
import { hideLoading, showLoading } from '../../../../store/features/loadingPage';
import SearchTable from '../../../common/Search/SearchTable';
import Pagination from '../../../common/Pagination';
import useMessage from '../../../common/toast/UseMessage';
import './styles.scss';

function Participant(props) {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const { id } = props;
  const [participants, setParticipants] = useState();
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();
  const [totalPage, setTotalPage] = useState(1);
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getListParticipants = async () => {
    dispatch(showLoading());
    try {
      const res = await tournamentApi.getParticipants(
        { limit: itemsPerPage, page: currentPage, name: search },
        id,
      );
      if (res.data) {
        setParticipants(res.data);
      }
      setTotalPage(res.data.totalPage);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    if (id) {
      getListParticipants();
    }
  }, [id, currentPage]);

  const handleSearchKeyDown = (event) => {
    if (event.key === EVENT__KEY.ENTER) {
      getListParticipants();
    }
  };
  const resetListParticipants = async () => {
    dispatch(showLoading());
    try {
      const res = await tournamentApi.getParticipants(
        { limit: itemsPerPage, page: 1, name: '' },
        id,
      );
      if (res.data) {
        setParticipants(res.data);
      }
      setCurrentPage(1);
      setTotalPage(res.data.totalPage);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleOnChangeSearch = (value) => {
    setSearch(value);
    if (!value) {
      resetListParticipants();
    }
  };

  return (
    <div className="participant-tab">
      <Row className="wrap-search-team">
        <Col lg="4" md="4" sm="12">
          <div className="total-participants">
            {t('total')}: {participants?.total} {t('participant')}
            {participants?.total > 1 && 's'}
          </div>
        </Col>
        <Col lg="4" md="8" sm="12" className="search-team">
          <SearchTable
            search={search}
            setSearch={handleOnChangeSearch}
            placeholder={t('search.searchTeam')}
            onKeyDown={handleSearchKeyDown}
            handleSearchIconPress={getListParticipants}
          />
        </Col>
      </Row>
      {participants?.data.length ? (
        <div>
          <h5 className="participant-title">{t('userName')}</h5>
          <div className="wrap-list-participant">
            {participants?.data.map((item) => (
              <Col key={item.id} className="wrap-item-participant">
                <img
                  className="participant-image"
                  src={item.avatar || TEAM.logoTeamDefault}
                  alt="img"
                />
                <h6 className="participant-name">
                  {item?.name !== 'null null' ? item?.name : 'DG Pub Member'}
                </h6>
              </Col>
            ))}
          </div>
          <Pagination
            className="pagination"
            totalPage={totalPage}
            onChange={handlePageChange}
            forcePage={currentPage}
          />
        </div>
      ) : (
        <div className="no-pariticipant-text">{t('noParticipants')}</div>
      )}
    </div>
  );
}

export default Participant;
