import { LOGIN_TYPE, WALLET_TYPE } from '../constants';

const getItemFromKey = (value, list, key = 'name') => {
  const item = list?.find(
    (item) => String(item?.[key]).toLowerCase() === String(value).toLowerCase(),
  );
  return item || '';
};

export default getItemFromKey;

export const convertObjectToQueryString = (parameters = {}, prefix = '?') => {
  const query = Object.keys(parameters)
    .map((key) => {
      let value = parameters[key];

      if (typeof value === 'boolean') value = value ? 1 : 0;

      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return prefix + query;
};

export function setupLocalStorage(userData, connectorName, loginType = LOGIN_TYPE.EMAIL) {
  try {
    const email = userData?.email;
    const googleEmail = userData?.googleCredential?.email;
    const lastName = userData?.lastName;
    const walletAddress = userData?.walletAddress;
    const walletType = userData?.walletType;
    localStorage.setItem('connector', connectorName);

    if (loginType === LOGIN_TYPE.EMAIL && email) {
      localStorage.setItem('email', email);
    }
    if (loginType === LOGIN_TYPE.GOOGLE && googleEmail) {
      localStorage.setItem('email', googleEmail);
    }
    if (walletAddress) {
      localStorage.setItem('walletAccount', walletAddress);
    } else {
      localStorage.setItem('walletAccount', 0);
    }
    localStorage.setItem('isCreateProfile', lastName ? 'true' : 'false');
    localStorage.setItem(
      'isLinkedWithEmaiAddress',
      (email || googleEmail) && walletAddress ? 'true' : 'false',
    );

    localStorage.setItem('isValidAA', walletType === WALLET_TYPE.ABSTRACTION ? 'true' : 'false');
    return true;
  } catch (error) {
    return false;
  }
}
