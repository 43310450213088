const ETH = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
};

const MATIC = {
  name: 'Matic',
  symbol: 'MATIC',
  decimals: 18,
};

const CELO = {
  name: 'Celo',
  symbol: 'CELO',
  decimals: 18,
};

const BNB = {
  name: 'Binance coin',
  symbol: 'BNB',
  decimals: 18,
};

function isExtendedChainInformation(chainInformation) {
  return !!chainInformation.nativeCurrency;
}

const getInfuraUrlFor = (network) =>
  process.env.infuraKey ? `https://${network}.infura.io/v3/${process.env.infuraKey}` : undefined;
const getAlchemyUrlFor = (network) =>
  process.env.alchemyKey
    ? `https://${network}.alchemyapi.io/v2/${process.env.alchemyKey}`
    : undefined;

export const MAINNET_CHAINS = {
  1: {
    urls: [
      getInfuraUrlFor('mainnet'),
      getAlchemyUrlFor('eth-mainnet'),
      'https://cloudflare-eth.com',
    ].filter(Boolean),
    name: 'Ethereum mainnet',
  },
  10: {
    urls: [getInfuraUrlFor('optimism-mainnet'), 'https://mainnet.optimism.io'].filter(Boolean),
    name: 'Optimism',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
  },
  42161: {
    urls: [getInfuraUrlFor('arbitrum-mainnet'), 'https://arb1.arbitrum.io/rpc'].filter(Boolean),
    name: 'Arbitrum One',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://arbiscan.io'],
  },
  137: {
    urls: [getInfuraUrlFor('polygon-mainnet'), 'https://polygon-rpc.com'].filter(Boolean),
    name: 'Polygon Mainnet',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://polygonscan.com'],
  },
  42220: {
    urls: ['https://forno.celo.org'],
    name: 'Celo',
    nativeCurrency: CELO,
    blockExplorerUrls: ['https://explorer.celo.org'],
  },
  56: {
    urls: [
      'https://bsc-dataseed.binance.org/',
      'https://bsc-dataseed1.defibit.io/',
      'https://bsc-dataseed1.ninicoin.io/',
    ],
    name: 'Binance coin',
    nativeCurrency: BNB,
    blockExplorerUrls: ['https://bscscan.com'],
  },
  888888888: {
    urls: ['https://rpc.ancient8.gg'],
    name: 'Ancient8 Mainnet',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://scan.ancient8.gg'],
  },
};

export const TESTNET_CHAINS = {
  5: {
    urls: [getInfuraUrlFor('goerli')].filter(Boolean),
    name: 'Görli',
  },
  420: {
    urls: [getInfuraUrlFor('optimism-goerli'), 'https://goerli.optimism.io'].filter(Boolean),
    name: 'Optimism Goerli',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://goerli-explorer.optimism.io'],
  },
  421613: {
    urls: [getInfuraUrlFor('arbitrum-goerli'), 'https://goerli-rollup.arbitrum.io/rpc'].filter(
      Boolean,
    ),
    name: 'Arbitrum Goerli',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://testnet.arbiscan.io'],
  },
  421614: {
    urls: [getInfuraUrlFor('arbitrum-sepolia'), 'https://sepolia-rollup.arbitrum.io/rpc'].filter(
      Boolean,
    ),
    name: 'Arbitrum Sepolia',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://sepolia.arbiscan.io'],
  },
  80001: {
    urls: [getInfuraUrlFor('polygon-mumbai')].filter(Boolean),
    name: 'Polygon Mumbai',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  },
  44787: {
    urls: ['https://alfajores-forno.celo-testnet.org'],
    name: 'Celo Alfajores',
    nativeCurrency: CELO,
    blockExplorerUrls: ['https://alfajores-blockscout.celo-testnet.org'],
  },
  97: {
    urls: [
      'https://speedy-nodes-nyc.moralis.io/e8f0d14076d3861865751b5e/bsc/testnet',
      'https://data-seed-prebsc-1-s3.binance.org:8545/',
      'https://data-seed-prebsc-2-s3.binance.org:8545/',
    ],
    name: 'Binance coin',
    nativeCurrency: BNB,
    blockExplorerUrls: ['https://testnet.bscscan.com'],
  },
  28122024: {
    urls: ['https://rpcv2-testnet.ancient8.gg'],
    name: 'Ancient8 Testnet',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://scanv2-testnet.ancient8.gg'],
  },
};

export const CHAINS = {
  ...MAINNET_CHAINS,
  ...TESTNET_CHAINS,
};

export const URLS = Object.keys(CHAINS).reduce((accumulator, chainId) => {
  const validURLs = CHAINS[Number(chainId)].urls;

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }

  return accumulator;
}, {});

export function getAddChainParameters(chainId) {
  const chainInformation = CHAINS[chainId];
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    };
  }
  return chainId;
}
