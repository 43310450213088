import React from 'react';
import { useTranslation } from 'react-i18next';
import SuccessTickIcon from '../../icons/SuccessTickIcon';
import LoadingIcon from '../../icons/LoadingIcon';
import {
  ClaimTicketSCStatus,
  ConfirmRequestBackendStatus,
  CreateRequestStatus,
} from '../../../constants';
import XIcon from '../../icons/XIcon';

function RedeemStatusPanel(props) {
  const { createRequestRedeemStatus, confirmRequestStatus, claimTicketStatus } = props;
  const { t } = useTranslation();

  const renderCreateRequestStatus = () => {
    if (createRequestRedeemStatus === CreateRequestStatus.CREATED) {
      return <SuccessTickIcon />;
    }
    if (
      createRequestRedeemStatus === CreateRequestStatus.FAILED ||
      createRequestRedeemStatus === CreateRequestStatus.REJECTED
    )
      return <XIcon />;
    return <LoadingIcon className="animate-spin" />;
  };

  const renderConfirmRequestStatus = () => {
    if (confirmRequestStatus === ConfirmRequestBackendStatus.CREATED) {
      return <SuccessTickIcon />;
    }
    if (confirmRequestStatus === ConfirmRequestBackendStatus.PENDING) {
      return <LoadingIcon className="animate-spin" />;
    }
    if (
      confirmRequestStatus === ConfirmRequestBackendStatus.FAILED ||
      confirmRequestStatus === ConfirmRequestBackendStatus.REJECTED
    ) {
      return <XIcon />;
    }
    return <LoadingIcon color="#827D9D" />;
  };

  const renderClaimTicketStatus = () => {
    if (claimTicketStatus === ClaimTicketSCStatus.CREATED) {
      return <SuccessTickIcon />;
    }
    if (claimTicketStatus === ClaimTicketSCStatus.PENDING) {
      return <LoadingIcon className="animate-spin" />;
    }
    if (
      claimTicketStatus === ClaimTicketSCStatus.FAILED ||
      claimTicketStatus === ClaimTicketSCStatus.REJECTED
    ) {
      return <XIcon />;
    }
    return <LoadingIcon color="#827D9D" />;
  };

  return (
    <div className="redeem-panel-wrapper">
      <div className="redeem-status-wrapper">
        <div className="status-line">
          {renderCreateRequestStatus()}
          <div className="line-between" />
        </div>
        <div>
          <p className="status-title">{t('Creating your request')}</p>
          <p className="status-description">{t('Initiate your claim request')}</p>
        </div>
      </div>
      <div className="redeem-status-wrapper">
        <div className="status-line">
          {renderConfirmRequestStatus()}
          <div className="line-between" />
        </div>
        <div>
          <p className="status-title">{t('Verifying your request')}</p>
          <p className="status-description">
            {t('You will be asked to sign your signature for verification')}
          </p>
        </div>
      </div>

      <div className="redeem-status-wrapper">
        {renderClaimTicketStatus()}
        <div>
          <p className="status-title">{t('Claiming rewards')}</p>
          <p className="status-description">{t('Once approved, receive tokens in your wallet.')}</p>
        </div>
      </div>
    </div>
  );
}

export default RedeemStatusPanel;
