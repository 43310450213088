import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UnauthorizePage from '../UnauthorizePage/UnauthorizePage';
import Layout from '../../components/common/Layout';
import { useAuthContext } from '../../context/AuthContext';
import './style.scss';
import RedemptionTab from '../../components/pages/RedeemReward/RedemptionTab';
import HistoryRedeemTab from '../../components/pages/RedeemReward/HistoryRedeemTab';

function RedeemReward(props) {
  const { dataConnect } = props;
  const { t } = useTranslation();
  const { isLogin } = useAuthContext();
  const [activeTab, setActiveTab] = useState({
    id: 'redeem',
    label: t('Claim rewards'),
  });
  const listSubTab = [
    { id: 'redeem', label: t('Claim rewards') },
    { id: 'history', label: t('redeemRewards.history') },
  ];

  return (
    <Layout dataConnect={dataConnect}>
      {isLogin ? (
        <div className="list-redeem-wrapper">
          <div className="list-tab">
            {listSubTab.map((tab) => (
              <TabLabel
                key={tab.id}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                label={tab.label}
                tab={tab}
              />
            ))}
          </div>
          <div className="tab-wrapper">
            {activeTab.id === 'redeem' && (
              <RedemptionTab isLogin={isLogin} dataConnect={dataConnect} />
            )}
            {activeTab.id === 'history' && <HistoryRedeemTab isLogin={isLogin} />}
          </div>
        </div>
      ) : (
        <UnauthorizePage />
      )}
    </Layout>
  );
}

export default RedeemReward;

function TabLabel({ tab, activeTab, setActiveTab }) {
  return (
    <div
      className={`tab-label ${
        activeTab?.id === tab?.id ? 'tab-label-active' : 'tab-label-deactive'
      }`}
      onClick={() => setActiveTab(tab)}
      aria-hidden
    >
      {tab?.label}
    </div>
  );
}
