import React from 'react';

function LightNingIcon(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.36 13.564l-.12-.011c-1.527-.14-2.291-.209-2.534-.731-.243-.523.197-1.151 1.077-2.408l3.583-5.118c.581-.83.872-1.245 1.122-1.157.25.088.217.594.15 1.605l-.203 3.048c-.062.92-.093 1.38.172 1.69.264.31.724.352 1.642.435l.12.011c1.528.139 2.292.208 2.534.731.243.523-.197 1.151-1.077 2.408l-3.583 5.118c-.58.83-.871 1.245-1.122 1.157-.25-.088-.217-.594-.15-1.605l.204-3.048c.061-.92.092-1.38-.173-1.69-.264-.31-.723-.352-1.642-.435z"
        fill="#0FF"
      />
      <path
        opacity={0.4}
        d="M19.305 4.24l-1.5 2h2l-1.5 2M7.305 16.24l-1.5 2h2l-1.5 2"
        stroke="#0FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LightNingIcon;
