import React from 'react';
import './styles.scss';

function SecondaryButton({
  className = '',
  accessoriesLeft = null,
  accessoriesRight = null,
  children,
  onClick = null,
  ...props
}) {
  return (
    <button type="button" {...props} onClick={onClick} className={`secondary-btn ${className}`}>
      {accessoriesLeft && <div className="">{accessoriesLeft}</div>}
      {children}
      {accessoriesRight && <div className="">{accessoriesRight}</div>}
    </button>
  );
}

export default SecondaryButton;
