import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HOME, ICONS } from '../../../../assets/imgs/index';
import {
  DGPUB_SOCIAL_NETWORK_LINK,
  ROUTER__LINK,
  WALLET_APPSTORE_URL,
  WALLET_GOOGLEPLAY_URL,
} from '../../../constants';
import SocialGroup from '../../common/SocialGroup';
import './styles.scss';

function HomeContent() {
  const { t } = useTranslation();
  const history = useHistory();
  const mediaList = [
    {
      name: 'Facebook',
      icon: ICONS.iconFacebook,
      path: DGPUB_SOCIAL_NETWORK_LINK.FACEBOOK,
    },
    {
      name: 'Telegram',
      icon: ICONS.iconTelegram,
      path: DGPUB_SOCIAL_NETWORK_LINK.TELEGRAM,
    },
    {
      name: 'Twitter',
      icon: ICONS.iconTwitter,
      path: DGPUB_SOCIAL_NETWORK_LINK.TWITTER,
    },
  ];
  return (
    <div
      className="wrap-content-home"
      style={{
        backgroundImage: `url(${HOME.bgHome})`,
        backgroundSize: `cover`,
        backgroundPosition: `center`,
        backgroundRepeat: `no-repeat`,
      }}
    >
      <div className="content">
        <div className="text-content-wrapper">
          <h1 className="title">GAME PLATFORM</h1>
          <h2 className="sub-title">Play Hard - Earn Big</h2>
          <h6 className="description">
            <span className="brand-name">DG.PUB</span> {t('aim to become')}
          </h6>
          <div className="store">
            <a href={WALLET_APPSTORE_URL} target="_blank" rel="noreferrer">
              <img className="store-img" src={HOME.appleStore} alt="apple store" />
            </a>
            <a href={WALLET_GOOGLEPLAY_URL} target="_blank" rel="noreferrer">
              <img className="store-img" src={HOME.googlePlay} alt="google play" />
            </a>
          </div>
          <div
            className="btn-stroke-fill btn-go-scholar"
            onClick={() => history.push(ROUTER__LINK.TOURNAMENT)}
            aria-hidden
          >
            {t('Explore Tournaments 1')}
          </div>
        </div>
        <div className="image-content-wrapper">
          <img src={HOME.imgHomePage} className="img-home-page" alt="icon" />
          <div className="bg-linear-shadow" />
        </div>
      </div>
      <div className="mobile-store">
        <a className="w-full" href={WALLET_APPSTORE_URL} target="_blank" rel="noreferrer">
          <img className="mobile-store-img" src={HOME.appleStore} alt="apple store" />
        </a>
        <a className="w-full" href={WALLET_GOOGLEPLAY_URL} target="_blank" rel="noreferrer">
          <img className="mobile-store-img" src={HOME.googlePlay} alt="google play" />
        </a>
      </div>
      <div
        className="btn-stroke-fill btn-mobile-go-scholar"
        onClick={() => history.push(ROUTER__LINK.TOURNAMENT)}
        aria-hidden
      >
        {t('Explore Tournaments 1')}
      </div>

      <div className="social-media-home">
        {mediaList.map((item) => (
          <SocialGroup key={item.name} icon={item.icon} name={item.name} path={item.path} />
        ))}
      </div>
    </div>
  );
}

export default HomeContent;
