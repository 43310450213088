import { useCallback } from 'react';
import { toast } from 'react-toastify';
import useSwitchNetwork from './useSwitchNetwork';
import useActiveWeb3React from './useActiveWeb3React';
import tournamentApi from '../services/tournament';
import { CUSTOM_TIMEOUT_MILISEC, ConfirmRequestBackendStatus } from '../constants';

export default function useConfirmRequest({ setStatus, setRedeemSignature, chainId }) {
  const { switchNetwork } = useSwitchNetwork();
  const { account: signerAddress } = useActiveWeb3React();
  const createConfirmRequest = useCallback(
    async ({ requestTransactionDetail }) => {
      try {
        console.log('START CONFIRM: === ', requestTransactionDetail);
        if (!requestTransactionDetail) {
          toast.error('Please close modal and try again later');
          return;
        }
        if (!signerAddress) {
          toast.error('Please connect your wallet');
          return;
        }

        const walletAddress = localStorage.getItem('walletAccount');
        if (walletAddress !== signerAddress) {
          toast.error('Please connect to your linked wallet');
          return;
        }
        await switchNetwork(chainId);
        // Call LOOP API to get detail from this data
        setStatus(ConfirmRequestBackendStatus.PENDING);
        let response = null;
        let elapsedTime = 0;
        const TIMEOUT_LIMIT = CUSTOM_TIMEOUT_MILISEC.CLAIM_SIGNATURE;
        const TIMEOUT_EACH_REQUEST = CUSTOM_TIMEOUT_MILISEC.CLAIM_EACH_REQUEST;

        while (elapsedTime < TIMEOUT_LIMIT) {
          // eslint-disable-next-line
          response = await tournamentApi.getRewardClaimDetail(requestTransactionDetail?.id);
          if (
            response?.data?.signature &&
            response?.data?.status === ConfirmRequestBackendStatus.PENDING
          ) {
            console.log('Received signature:', response.data.signature);
            setStatus(ConfirmRequestBackendStatus.CREATED);
            elapsedTime = TIMEOUT_LIMIT + 1;
            break;
          }
          // eslint-disable-next-line
          await new Promise((resolve) => setTimeout(resolve, TIMEOUT_EACH_REQUEST));
          elapsedTime += TIMEOUT_EACH_REQUEST;
        }
        if (elapsedTime === TIMEOUT_LIMIT) {
          console.log('Timeout: 30 seconds elapsed.');
          setStatus(ConfirmRequestBackendStatus.FAILED);
        }
        setRedeemSignature(response?.data);
      } catch (error) {
        console.log('Create Confirm Request Error: ', error);
        if (error.message.includes('user rejected transaction')) {
          toast.error('Oops, you have rejected request');
          setStatus(ConfirmRequestBackendStatus.REJECTED);
        } else {
          toast.error('Failed to verify signature. Please try again later');
          setStatus(ConfirmRequestBackendStatus.FAILED);
        }
      }
    },
    [chainId, signerAddress],
  );

  return { createConfirmRequest };
}
