import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ERR_MESSAGE,
  TEAM_NOT_ENOUGH_MEMBER_MESSAGE,
  TEAM_OVER_MAX_SIZE_MESSAGE,
} from '../../../constants';
import tournamentApi from '../../../services/tournament';
import { useAppDispatch } from '../../../store';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import TButton from '../../common/Button';
import useMessage from '../../common/toast/UseMessage';
import RegistrationForm from './ModalJoinTournament/RegistrationForm';
import SelectTeam from './ModalJoinTournament/SelectTeam';
import './styles.scss';
import { useAuthContext } from '../../../context/AuthContext';

function ModalJoinTournament(props) {
  const { show, setShow, registrationForm, getCurrentTournament, teamSize, maxTeamSize, id } =
    props;
  const loginRef = useRef(null);
  const [comment, setComment] = useState();
  const [listMyTeam, setListMyTeam] = useState([]);
  const dispatch = useAppDispatch();
  const [isTeam, setIsTeam] = useState(true);
  const [isForm, setIsForm] = useState(false);
  const [teamId, setTeamId] = useState('');
  const [email, setEmail] = useState('');
  const { t } = useTranslation();
  const [currentMember, setCurrentMember] = useState([]);
  const { isLogin } = useAuthContext();

  const { openMessageError, openMessage } = useMessage();
  const getMyTeam = async () => {
    dispatch(showLoading());
    try {
      const { data } = await tournamentApi.getMyTeam();
      setListMyTeam(data.data.filter((item) => item.isOwner));
    } catch (error) {
      openMessageError(error.response.data.errors[0].msg || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const getCurrentMember = async () => {
    const params = {
      name: '',
    };
    dispatch(showLoading());
    try {
      const { data } = await tournamentApi.getCurrentMember(params, teamId);
      setCurrentMember(data);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const inviteEmail = async () => {
    const data = {
      teamId: Number(teamId),
      emails: [email],
    };
    dispatch(showLoading());
    try {
      await tournamentApi.inviteEmail(data);
      openMessage({ message: t('success.sendTeamSuccessful') });
    } catch (error) {
      openMessageError(error.response.data.errors[0].msg || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleJoinTournament = async () => {
    if (!teamId) {
      openMessageError(t('Select to join'));
      return;
    }
    if (teamSize > currentMember.length) {
      openMessageError(TEAM_NOT_ENOUGH_MEMBER_MESSAGE);
      return;
    }
    if (currentMember.length > maxTeamSize) {
      openMessageError(TEAM_OVER_MAX_SIZE_MESSAGE);
      return;
    }
    dispatch(showLoading());
    try {
      const payload = {
        teamId: Number(teamId),
        tournamentId: Number(id),
        isCompleteForm: Number(isForm),
        comment,
      };
      await tournamentApi.joinTournament(payload);
      getCurrentTournament();
      openMessage({ message: t('Apply tournament') });
      setShow(false);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    if (isTeam && isLogin) getMyTeam();
  }, [isTeam]);
  useEffect(() => {
    if (teamId) getCurrentMember();
  }, [teamId]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (loginRef.current && !loginRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [loginRef]);
  return (
    <div
      className={show ? 'wrap-modal-custom join-tournament d-block' : 'wrap-modal-custom'}
      ref={loginRef}
    >
      {isTeam ? (
        <SelectTeam
          listMyTeam={listMyTeam}
          teamId={teamId}
          setTeamId={setTeamId}
          currentMember={currentMember}
          email={email}
          setEmail={setEmail}
          inviteEmail={inviteEmail}
          handleJoinTournament={handleJoinTournament}
          titleSelectTeam={t('Participate')}
          teamSize={teamSize}
        />
      ) : (
        <RegistrationForm
          titleRegistration={t('Registration question')}
          isForm={isForm}
          comment={comment}
          setComment={setComment}
          setIsForm={setIsForm}
          setIsTeam={setIsTeam}
          registrationForm={registrationForm}
        />
      )}
      <TButton
        title={t('donate.cancel')}
        className="mb-2"
        width="full-width"
        type="reverse"
        onClick={() => setShow(false)}
      />
    </div>
  );
}

export default ModalJoinTournament;
