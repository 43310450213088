import axios from 'axios';
import JSCookie from 'js-cookie';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { TIME_OUT_API_MILISEC, refreshAccessTokenUrl } from '../constants';
import { browserRedirectToIndexAfterSignOut } from '../lib/redirect';
import processDataLogin from '../lib/processDataLogin';
import { getCheckLogin } from './authFunctions';

const olAxios = axios.create({
  baseURL: process.env.APP_ENDPOINT,
  timeout: TIME_OUT_API_MILISEC,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-key': process.env.DG_API_KEY,
  },
});

const requestHandler = (request) => {
  const accessToken = JSCookie.get('token');
  const acceptLanguage = localStorage.getItem('i18nextLng');
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }
  if (acceptLanguage) {
    request.headers['Accept-Language'] = acceptLanguage;
  }
  return request;
};

const redirectToError = (response) => {
  switch (response.status) {
    case 401:
      break;
    case 403:
      window.location = '/error/404';
      break;
    case 404:
      // window.location = '/error/404';
      break;
    case 503:
      window.location = '/maintain';
      break;
    case 504:
      window.location = '/error/504';
      break;
    default:
      break;
  }
};

const responseHandler = (response) => response;

const errorHandler = (error) => {
  const { response } = error;
  redirectToError(response);
  return Promise.reject(error);
};

olAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error),
);

olAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error),
);

const refreshAuthLogic = async () => {
  const refreshToken = JSCookie.get('refreshToken');
  const checkLogin = getCheckLogin();
  const onSuccess = (response) => {
    const data = response?.data;
    processDataLogin(data);
    if (checkLogin) {
      checkLogin();
    }
  };

  const onError = (error) => {
    JSCookie.remove('token');
    JSCookie.remove('refreshToken');
    localStorage.clear();
    browserRedirectToIndexAfterSignOut();
    return error;
  };

  const payload = {
    refreshToken,
  };

  if (refreshToken) {
    try {
      const response = await olAxios({
        method: 'post',
        url: refreshAccessTokenUrl,
        data: payload,
      });
      onSuccess(response);
    } catch (error) {
      onError(error);
    }
  }
};

createAuthRefreshInterceptor(olAxios, refreshAuthLogic);

export default {
  get: olAxios.get,
  post: olAxios.post,
  put: olAxios.put,
  delete: olAxios.delete,
  patch: olAxios.patch,
};
