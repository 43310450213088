import React from 'react';

function DggIcon(props) {
  return (
    <svg
      width={46}
      height={40}
      viewBox="0 0 46 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1199_5587)">
        <path
          d="M34.64.292H15.622v7.06h-8.15L0 20.292 7.47 33.23h8.152v7.062H34.64l11.548-20-11.548-20zM30.564 33.23H15.626L8.154 20.292l7.472-12.94h14.938l7.472 12.94-7.472 12.938z"
          fill="#0FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1199_5587">
          <path fill="#fff" transform="translate(0 .646)" d="M0 0H46V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DggIcon;
