import React from 'react';

function LogoutIcon(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M2.5 6.947C2.5 4.496 4.53 2.5 7.025 2.5h4.96c2.49 0 4.515 1.99 4.515 4.437v11.116c0 2.452-2.03 4.447-4.526 4.447H7.015c-2.49 0-4.515-1.99-4.515-4.437V6.947z"
        fill="#fff"
      />
      <path
        d="M22.279 11.955l-2.846-2.91a.736.736 0 00-1.06.003.785.785 0 00.002 1.089l1.559 1.593H10.049a.76.76 0 00-.75.77c0 .426.336.77.75.77h9.885l-1.56 1.593a.785.785 0 00-.001 1.089.74.74 0 00.531.227.74.74 0 00.53-.225l2.845-2.908a.783.783 0 000-1.091z"
        fill="#fff"
      />
    </svg>
  );
}

export default LogoutIcon;
