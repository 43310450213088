import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import SecondaryButton from '../../components/common/Button/SecondaryButton';
import TButton from '../../components/common/Button';
import {
  LIST_BRACKET_TOURNAMENT,
  LIST_STATUS_TOURNAMENT,
  LIST_TYPE_TOURNAMENT,
} from '../../constants';
import PrimaryInputCheckbox from '../../components/common/Input/PrimaryInputCheckbox';
import ArrowDownIcon from '../../components/icons/ArrowDownIcon';

const sidebar = {
  open: () => ({
    pointerEvents: 'all',
    zIndex: 9999,
    y: 0,
  }),
  closed: {
    pointerEvents: 'none',
    y: 1000,
  },
};

function TournamentFilterMobile(props) {
  const { filter, setFilter, showDialog, setShowDialog, listGame, handleClearFilter } = props;
  const { t } = useTranslation();
  const LIST_CONDITION_FILTER = [
    {
      id: 1,
      title: 'Play mode',
      filterType: 'type',
      options: LIST_TYPE_TOURNAMENT,
      type: 'Checkbox',
    },
    {
      id: 2,
      title: 'Bracket',
      filterType: 'bracket',
      options: LIST_BRACKET_TOURNAMENT,
      type: 'Checkbox',
    },
    {
      id: 3,
      title: 'Status',
      filterType: 'status',
      options: LIST_STATUS_TOURNAMENT,
      type: 'Checkbox',
    },
    {
      id: 4,
      title: 'Game',
      filterType: 'gameId',
      options: listGame,
      type: 'Checkbox',
    },
  ];
  const toggleClickFilter = () => {
    setShowDialog(!showDialog);
  };

  return (
    <div className="tournament_filter_mobile_wrapper">
      <motion.div
        initial={false}
        animate={showDialog ? 'open' : 'closed'}
        // @ts-ignore
        variants={sidebar}
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          height: '100vh',
          width: '100vw',
          overflowY: 'auto',
        }}
        transition={{ duration: 0.25 }}
        className="filter_mobile_motion"
      >
        <motion.div className="filter_mobile">
          <div className="header">
            <div className="filter_title">{t('Filter')}</div>
            <XICon onClick={toggleClickFilter} />
          </div>
          <div className="content height-filter-tournament">
            {LIST_CONDITION_FILTER.map((item) => (
              <FilterDropdown
                key={`filter-${item?.id}`}
                filterCondition={item}
                filter={filter}
                setFilter={setFilter}
              />
            ))}
          </div>
          <div className="footer">
            <SecondaryButton
              className="btn_clear"
              onClick={() => {
                handleClearFilter();
                setShowDialog(false);
              }}
            >
              {t('Reset')}
            </SecondaryButton>
            <TButton className="apply_button" onClick={toggleClickFilter}>
              {t('Apply')}
            </TButton>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default TournamentFilterMobile;

function FilterDropdown({ filterCondition, filter, setFilter }) {
  const [ispExpanded, setIspExpanded] = useState(true);
  const options = filterCondition?.options || [];
  const title = filterCondition?.title;
  const type = filterCondition?.type;
  const filterType = filterCondition?.filterType;

  const handleInputCheckbox = (key) => {
    setFilter((prevFilter) => {
      const currentFilter = prevFilter[filterType] || [];
      if (currentFilter?.includes(key)) {
        return {
          ...prevFilter,
          [filterType]: currentFilter?.filter((item) => item !== key),
        };
      }
      return {
        ...prevFilter,
        [filterType]: [...currentFilter, key],
      };
    });
  };

  return (
    <div className="filter_item bg_color">
      <motion.div
        className="filter_title_wrapper cursor-pointer"
        onClick={() => setIspExpanded(!ispExpanded)}
      >
        <div className="title">{title}</div>
        <div className={`${ispExpanded && 'rotate-180'} smooth-transform`}>
          <ArrowDownIcon />
        </div>
      </motion.div>

      <AnimatePresence initial>
        {ispExpanded && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: {
                opacity: 0,
                height: 0,
                animationDelay: '1',
              },
            }}
            transition={{
              duration: 0.4,
              ease: [0.04, 0.62, 0.73, 0.98],
            }}
            className="item_list"
          >
            {type === 'Checkbox' &&
              options.map((i) => (
                <PrimaryInputCheckbox
                  key={`${filterType}-${i?.id}`}
                  accessoriesRight={i?.name}
                  value={i?.id}
                  onChange={() => handleInputCheckbox(i?.id)}
                  checked={filter[filterType]?.includes(i?.id)}
                />
              ))}
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
}

function XICon({ ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.462 21.999L2 3.539 3.538 2 22 20.46 20.462 22z"
        fill="#827D9D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 20.462l18.462-18.46L22 3.538 3.538 22 2 20.462z"
        fill="#827D9D"
      />
    </svg>
  );
}
