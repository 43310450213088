import React, { useEffect, useRef, useState } from 'react';
import ArrowDownIcon from '../../icons/ArrowDownIcon';

function SelectGameDropdown({ listDropdown, showing, setShowing, classNameDropdown = '' }) {
  const node = useRef();
  const [isOpen, toggleOpen] = useState(false);

  const toggleOpenMenu = () => {
    if (listDropdown?.length > 0) {
      toggleOpen(!isOpen);
    }
  };

  const handleClickOutside = (e) => {
    // @ts-ignore
    if (node.current?.contains(e.target)) {
      return;
    }
    toggleOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="select-chain-custom" ref={node}>
      <div className={`select ${classNameDropdown}`} onClick={toggleOpenMenu} aria-hidden>
        <div className="select-chain-wrapper">
          {showing?.thumnail && (
            <img className="img-game" src={showing?.thumnail} alt="nft-token" />
          )}
          <h6 className="select-default">{showing?.name || 'Select'}</h6>
        </div>
        <div className={`${isOpen && 'rotate-180'} smooth-transform`}>
          <ArrowDownIcon />
        </div>
      </div>
      <div className="list-item">
        <div className={`dropdown-body ${isOpen && 'open'}`}>
          {listDropdown?.map((i) => (
            <DropDownItem key={i?.id} data={i} setShowing={setShowing} toggleOpen={toggleOpen} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default SelectGameDropdown;

function DropDownItem({ data, setShowing, toggleOpen }) {
  return (
    <div
      onClick={() => {
        setShowing(data);
        toggleOpen(false);
      }}
      aria-hidden
      className="chain-item-wrapper smooth-transform cursor-pointer"
    >
      <img className="img-game" src={data?.thumnail} alt="nft-token" />
      <p className="chain-name">{data?.name}</p>
    </div>
  );
}
