import React from 'react';

function TicketPrizeIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M2 7.034a1 1 0 011-1h2.474a1 1 0 011 1v9.932a1 1 0 01-1 1H3a1 1 0 01-1-1V7.034z"
        fill="#fff"
      />
      <path
        d="M3.546 7.15a.214.214 0 01.428 0v9.7a.214.214 0 01-.429 0v-9.7zM2.699 7.15a.214.214 0 01.428 0v9.7a.214.214 0 01-.428 0v-9.7z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.162 6.034a1 1 0 00-1 1v9.932a1 1 0 001 1H21a1 1 0 001-1V7.034a1 1 0 00-1-1H8.162zm6.902 3.38a.508.508 0 00-.966 0l-.374 1.154a.508.508 0 01-.483.35h-1.212a.508.508 0 00-.299.919l.981.712c.178.13.252.359.184.568l-.374 1.153a.508.508 0 00.781.568l.98-.713a.508.508 0 01.598 0l.98.713c.398.289.934-.1.782-.568l-.375-1.153a.508.508 0 01.184-.568l.981-.712a.508.508 0 00-.298-.919H15.92a.508.508 0 01-.482-.35l-.375-1.153z"
        fill="#fff"
      />
    </svg>
  );
}

export default TicketPrizeIcon;
