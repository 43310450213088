import sample from 'lodash/sample';
import { SupportedChainId } from '../constants';

if (!process.env.ARBITRUM_NODE_1 || !process.env.POLYGON_NODE_1 || !process.env.ANCIENT8_NODE_1) {
  throw Error('One base RPC URL is undefined');
}

// Array of available nodes to connect to
export const nodes = [
  process.env.ARBITRUM_NODE_1,
  process.env.POLYGON_NODE_1,
  process.env.ANCIENT8_NODE_1,
];

export const nodesByChainId = {
  [SupportedChainId.ARBITRUM_ONE]: process.env.ARBITRUM_NODE_1,
  [SupportedChainId.ARBITRUM_SEPOLIA]: process.env.ARBITRUM_NODE_1,
  [SupportedChainId.POLYGON_MAINNET]: process.env.POLYGON_NODE_1,
  [SupportedChainId.POLYGON_MUMBAI]: process.env.POLYGON_NODE_1,
  [SupportedChainId.ANCIENT8_TESTNET]: process.env.ANCIENT8_NODE_1,
  [SupportedChainId.ANCIENT8_MAINNET]: process.env.ANCIENT8_NODE_1,
};

const getRpcUrl = () => sample(nodes);

export default getRpcUrl;
