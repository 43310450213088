import React, { useState, useEffect } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { useQueries } from '@tanstack/react-query';
import TButton from '../../common/Button';
import RedemptionModal from './RedemptionModal';
import {
  ANCIENT8_CHAIN_ID,
  ClaimTicketSCStatus,
  ConfirmRequestBackendStatus,
  CreateRequestStatus,
  GAME_MEOW_NAME,
  WALLET_ERROR_REASON,
} from '../../../constants';
import useCreateRequestRedeem from '../../../hooks/useCreateRequestRedeem';
import useConfirmRequest from '../../../hooks/useConfirmRequest';
import SelectChainDropdown from './SelectChainDropdown';
import { REDEEM_IMG, TICKET_TYPE_IMG } from '../../../../assets/imgs';
import tournamentApi from '../../../services/tournament';
import useActiveWeb3React from '../../../hooks/useActiveWeb3React';
import useSwitchNetwork from '../../../hooks/useSwitchNetwork';
import CONTRACT_ABI from '../../../abis/tournament.json';
import formatPointNumber from '../../../lib/formatPointNumber';
import shortenAddress from '../../../utils/shortenAddress';
import ModalConnectWallet from '../../common/ModalConnectWallet/ModalConnectWallet';
import ModalRedeemSuccess from './ModalRedeemSuccess';
import ToastErrorInsufficientFund from '../../common/toast/ToastErrorInsufficientFund';
import getItemFromKey from '../../../lib';
import scholarshipApi from '../../../services/scholarship';
import SelectGameDropdown from './SelectGameDropdown';
import { useAuthContext } from '../../../context/AuthContext';
import { CONTRACT_BY_CHAIN } from '../../../constants/contractConstant';

function RedemptionTab(props) {
  const { t } = useTranslation();
  const { dataConnect } = props;
  const [chainSelected, setChainSelected] = useState();
  const [selectedGame, setSelectedGame] = useState();
  const [pointSelected, setPointSelected] = useState();
  const [showModalRedeem, setShowModalRedeem] = useState(false);
  const [showRetryBtn, setShowRetryBtn] = useState(false);
  const [showModalConnectWallet, setShowModalConnectWallet] = useState(false);

  const [createRequestRedeemStatus, setCreateRequestRedeemStatus] = useState(
    CreateRequestStatus.INIT,
  );
  const [redeemRequest, setRedeemRequest] = useState();

  const [confirmRequestStatus, setConfirmRequestStatus] = useState(
    ConfirmRequestBackendStatus.INIT,
  );
  const [redeemSignature, setRedeemSignature] = useState();
  const [claimTicketStatus, setClaimTicketStatus] = useState(ClaimTicketSCStatus.INIT);

  const [isLoadingListGame, setIsLoadingListGame] = useState(false);
  const [listGame, setListGame] = useState();
  const [listChain, setListChain] = useState([]);
  const [tokenBalanceDetail, setTokenBalanceDetail] = useState();

  const [showModalRedeemSuccess, setShowModalRedeemSuccess] = useState(false);
  const { account: signerAddress, provider: activeWeb3Provider } = useActiveWeb3React(
    chainSelected?.chain_id,
  );
  const { walletAccount, userEmail } = useAuthContext();
  const { switchNetwork } = useSwitchNetwork();
  const [isRedeemProcessing, setIsRedeemProcessing] = useState(false);

  const results = useQueries({
    queries: [
      {
        queryKey: ['getListGame'],
        queryFn: async () => {
          setIsLoadingListGame(true);
          const res = await scholarshipApi.getListGame();
          setListGame(res?.data);
          setIsLoadingListGame(false);
          return res?.data;
        },
      },
      {
        queryKey: ['getListChain'],
        queryFn: async () => {
          const res = await scholarshipApi.getListNetworks({
            isTournamentNetwork: true,
          });
          setListChain(res?.data);
          return res?.data;
        },
      },
      {
        queryKey: ['getTokenBalanceDetail', chainSelected?.chain_id, selectedGame?.id],
        queryFn: async () => {
          if (chainSelected?.chain_id && selectedGame?.id) {
            const submitObj = {
              chainId: chainSelected?.chain_id,
              gameId: selectedGame?.id,
            };
            const res = await tournamentApi.getAccountPoint(submitObj);
            setTokenBalanceDetail(res?.data);
            return res?.data;
          }
        },
        enabled: !!chainSelected?.chain_id && !!selectedGame?.id,
      },
    ],
  });

  useEffect(() => {
    if (listGame) {
      const meowGame = getItemFromKey(GAME_MEOW_NAME, listGame, 'name');
      setSelectedGame(meowGame);
    }
  }, [listGame]);

  useEffect(() => {
    if (listChain) {
      const a8Chain = getItemFromKey(ANCIENT8_CHAIN_ID, listChain, 'chain_id');
      setChainSelected(a8Chain);
    }
  }, [listChain]);

  const { createRequestRedeem } = useCreateRequestRedeem({
    setStatus: (status) => {
      setCreateRequestRedeemStatus(status);
    },
    setRedeemRequest: (data) => {
      setRedeemRequest(data);
    },
    chainId: chainSelected?.chain_id,
  });

  const { createConfirmRequest } = useConfirmRequest({
    setStatus: (status) => {
      setConfirmRequestStatus(status);
    },
    setRedeemSignature: (data) => {
      setRedeemSignature(data);
    },
    chainId: chainSelected?.chain_id,
  });

  const handleSubmitRequest = () => {
    setIsRedeemProcessing(true);
    setShowModalRedeem(true);
    createRequestRedeem({
      redeemRequest: {
        gamePointId: pointSelected?.gamePoint?.id,
      },
    });

    setCreateRequestRedeemStatus(CreateRequestStatus.INIT);
    setConfirmRequestStatus(ConfirmRequestBackendStatus.INIT);
    setClaimTicketStatus(ClaimTicketSCStatus.INIT);
  };

  const handleCreateClaimTicket = async () => {
    try {
      const chainId = chainSelected?.chain_id;
      if (!redeemSignature) {
        toast.error(t('require.closeModal'));
        return;
      }

      if (!signerAddress) {
        toast.error(t('require.connectyourWallet'));
        return;
      }

      if (walletAccount !== signerAddress) {
        toast.error(
          `${t('error.pleaseSwitch')} ${shortenAddress(walletAccount, 4)} ${t(
            'error.linkedAccount',
          )}`,
        );
        return;
      }

      setClaimTicketStatus(ClaimTicketSCStatus.PENDING);
      await switchNetwork(chainId);

      const signer = activeWeb3Provider.getSigner();
      const { tournamentAddress } = CONTRACT_BY_CHAIN[chainId];
      const contract = new ethers.Contract(tournamentAddress, CONTRACT_ABI, signer);

      console.log(
        'SUBMIT TO CONTRACT: ',
        new BigNumber(redeemSignature?.amount)
          .multipliedBy(10 ** redeemSignature?.decimals)
          .toFixed(),
        redeemSignature?.id,
        redeemSignature?.tokenAddress,
        redeemSignature?.signature,
      );

      const createClaimTransaction = await contract.claimReward(
        new BigNumber(redeemSignature?.amount)
          .multipliedBy(10 ** redeemSignature?.decimals)
          .toFixed(),
        redeemSignature?.id,
        redeemSignature?.tokenAddress,
        redeemSignature?.signature,
      );
      const response = await createClaimTransaction.wait();

      if (response?.status === 0) {
        setClaimTicketStatus(ClaimTicketSCStatus.FAILED);
      } else {
        const txHash = response?.transactionHash;
        const submitObj = {
          chainId: chainSelected?.chain_id,
          transactionHash: txHash,
        };
        await tournamentApi.sendClaimTicketLog(submitObj);
        setClaimTicketStatus(ClaimTicketSCStatus.CREATED);
        // Refetch data
        results[2].refetch();
        setShowModalRedeem(false);
        setShowModalRedeemSuccess(true);
      }
      setIsRedeemProcessing(false);
      // claim done, set all to null
    } catch (error) {
      setIsRedeemProcessing(false);
      results[2].refetch();
      console.log('error: ', error);
      if (error.message.includes('user rejected transaction')) {
        toast.error(t('alert.rejectedRequest'));
        setClaimTicketStatus(ClaimTicketSCStatus.REJECTED);
      } else if (
        error?.reason === WALLET_ERROR_REASON.INSUFFICIENT_FUND ||
        error?.reason === WALLET_ERROR_REASON.CANNOT_ESTIMATE
      ) {
        toast.error(
          <ToastErrorInsufficientFund chainId={chainSelected?.chain_id} account={signerAddress} />,
        );
        setClaimTicketStatus(ClaimTicketSCStatus.FAILED);
      } else {
        toast.error(
          error?.data?.message || error?.reason || error?.message || t('error.errorMessage'),
        );
        setClaimTicketStatus(ClaimTicketSCStatus.FAILED);
      }
    }
  };

  const handleClickRetryBtn = () => {
    setIsRedeemProcessing(true);
    if (createRequestRedeemStatus === CreateRequestStatus.FAILED) {
      createRequestRedeem({
        redeemRequest: {
          gamePointId: pointSelected?.id,
        },
      });
    }
    if (confirmRequestStatus === ConfirmRequestBackendStatus.FAILED) {
      createConfirmRequest({
        requestTransactionDetail: redeemRequest,
      });
    }
    if (
      claimTicketStatus === ClaimTicketSCStatus.FAILED ||
      claimTicketStatus === ClaimTicketSCStatus.REJECTED
    ) {
      handleCreateClaimTicket();
    }
  };

  const handleClickCloseBtn = () => {
    setShowModalRedeem(false);
    setCreateRequestRedeemStatus(CreateRequestStatus.INIT);
    setConfirmRequestStatus(ConfirmRequestBackendStatus.INIT);
    setClaimTicketStatus(ClaimTicketSCStatus.INIT);
    setShowRetryBtn(false);
  };

  useEffect(() => {
    if (
      createRequestRedeemStatus === CreateRequestStatus.FAILED ||
      createRequestRedeemStatus === CreateRequestStatus.REJECTED
    ) {
      setShowRetryBtn(true);
    }
    if (createRequestRedeemStatus === CreateRequestStatus.CREATED && showModalRedeem) {
      console.log('RUN CREATE CONFIRM === ', createRequestRedeemStatus, redeemRequest);
      createConfirmRequest({
        requestTransactionDetail: redeemRequest,
      });
      setShowRetryBtn(false);
    }
  }, [createRequestRedeemStatus]);

  useEffect(() => {
    if (
      confirmRequestStatus === ConfirmRequestBackendStatus.FAILED ||
      confirmRequestStatus === ConfirmRequestBackendStatus.REJECTED
    ) {
      setShowRetryBtn(true);
    }
    if (confirmRequestStatus === ConfirmRequestBackendStatus.CREATED && showModalRedeem) {
      handleCreateClaimTicket();
      setShowRetryBtn(false);
    }
  }, [confirmRequestStatus]);

  useEffect(() => {
    if (
      claimTicketStatus === ClaimTicketSCStatus.FAILED ||
      claimTicketStatus === ClaimTicketSCStatus.REJECTED
    ) {
      setShowRetryBtn(true);
    }
    if (claimTicketStatus === ClaimTicketSCStatus.CREATED && showModalRedeem) {
      toast.success(t('success.claimTicketSucceeded'));
      setShowRetryBtn(false);
    }
  }, [claimTicketStatus]);

  const handleResetStatus = () => {
    setCreateRequestRedeemStatus(CreateRequestStatus.INIT);
    setConfirmRequestStatus(ConfirmRequestBackendStatus.INIT);
    setClaimTicketStatus(ClaimTicketSCStatus.INIT);
    setIsRedeemProcessing(false);
  };

  const handleLinkWallet = () => {
    dataConnect.linkWallet();
  };

  useEffect(() => {
    if (tokenBalanceDetail?.pointBalances?.length > 0) {
      setPointSelected(tokenBalanceDetail?.pointBalances[0]);
    }
  }, [tokenBalanceDetail?.pointBalances]);

  const renderBtnSubmitRequest = () => {
    if (!signerAddress) {
      return (
        <>
          <TButton
            title={t('login.connectWallet')}
            className="btn-connect-wallet"
            onClick={() => {
              setShowModalConnectWallet(true);
            }}
          />
          <ModalConnectWallet
            showDialog={showModalConnectWallet}
            setShowDialog={setShowModalConnectWallet}
          />
        </>
      );
    }
    if (userEmail && !Number(walletAccount)) {
      return (
        <TButton
          title={t('require.link')}
          className="btn-connect-wallet"
          onClick={handleLinkWallet}
        />
      );
    }
    return (
      <TButton
        title={t('Claim Token')}
        className="btn-submit-redeem"
        onClick={handleSubmitRequest}
        disabled={isRedeemProcessing || !pointSelected?.balance}
      />
    );
  };

  return (
    <div className="redemption-tab-wrapper">
      <div className="redemption-infor">
        {isLoadingListGame ? (
          <div className="skeleton-loading skeleton-banner" />
        ) : (
          <img
            src={selectedGame?.thumnail || REDEEM_IMG.bannerGameDefault}
            alt="game-banner"
            className="game-banner"
          />
        )}

        <div className="point-game-wrapper">
          <div className="game-chain-selection">
            <div>
              <RedeemTitle>{t('Game')}</RedeemTitle>
              <SelectGameDropdown
                listDropdown={listGame || []}
                showing={selectedGame}
                setShowing={setSelectedGame}
                classNameDropdown="height-chain-custom"
              />
            </div>
            <div>
              <RedeemTitle>{t('Network')}</RedeemTitle>
              <SelectChainDropdown
                listDropdown={listChain || []}
                showing={chainSelected}
                setShowing={setChainSelected}
                classNameDropdown="height-chain-custom"
                type="chain"
              />
            </div>
            <div>
              <RedeemTitle>Token</RedeemTitle>
              <SelectChainDropdown
                listDropdown={tokenBalanceDetail?.pointBalances || []}
                showing={pointSelected}
                setShowing={setPointSelected}
                classNameDropdown="height-chain-custom"
                type="token"
              />
            </div>
          </div>
          <div className="break-line" />
          <div className="claim-token-wrapper">
            <div>
              <RedeemTitle>{t('My Balance')}</RedeemTitle>
              <div className="my-balance">
                <img
                  className="coin-img"
                  src={pointSelected?.tokenImage?.url || TICKET_TYPE_IMG.ticketToken}
                  alt="coin-img"
                />
                <div className="balance-number">
                  {pointSelected?.balance ? formatPointNumber(pointSelected?.balance) : '0'}
                </div>
              </div>
            </div>
            <div className="claim-token-btn">{renderBtnSubmitRequest()}</div>
          </div>
        </div>
      </div>
      {showModalRedeem && (
        <RedemptionModal
          show={showModalRedeem}
          setShow={setShowModalRedeem}
          chainSelected={chainSelected}
          selectedGame={selectedGame}
          createRequestRedeemStatus={createRequestRedeemStatus}
          confirmRequestStatus={confirmRequestStatus}
          claimTicketStatus={claimTicketStatus}
          handleClickRetryBtn={handleClickRetryBtn}
          handleClickCloseBtn={handleClickCloseBtn}
          handleResetStatus={handleResetStatus}
          showRetryBtn={showRetryBtn}
          tokenBalanceDetail={pointSelected}
        />
      )}
      {showModalRedeemSuccess && (
        <ModalRedeemSuccess
          show={showModalRedeemSuccess}
          setShow={setShowModalRedeemSuccess}
          redeemRequest={redeemRequest}
        />
      )}
    </div>
  );
}

export default RedemptionTab;

function RedeemTitle({ children }) {
  return <div className="small-redeem-title">{children}</div>;
}
