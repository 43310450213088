import { QR_CODE_IMG } from '../../assets/imgs';
import { metaMask } from '../connectors/metaMask';
import { walletConnectV2 } from '../connectors/walletConnectV2';
import { ANCIENT8_CHAIN_ID, ARBITRUM_CHAIN_ID, POLYGON_CHAIN_ID } from '../constants';

export const supportedChainIds = [ARBITRUM_CHAIN_ID || POLYGON_CHAIN_ID || ANCIENT8_CHAIN_ID].map(
  (chainId) => Number(chainId),
);

export const ConnectorNames = {
  Email: 'Email',
  DgPubWalletDesktop: 'DGG Wallet Desktop',
  DgPubWalletMobile: 'DGG Wallet Mobile',
  Metamask: 'Metamask',
  WalletConnect: 'WalletConnect',
};

export const connectorsByName = {
  [ConnectorNames.Email]: null,
  [ConnectorNames.DgPubWalletDesktop]: walletConnectV2,
  [ConnectorNames.DgPubWalletMobile]: metaMask,
  [ConnectorNames.Metamask]: metaMask,
  [ConnectorNames.WalletConnect]: walletConnectV2,
};

export function getWalletServiceIcon(name) {
  switch (name) {
    case ConnectorNames.DgPubWalletDesktop:
      return QR_CODE_IMG.dgPubWallet;
    case ConnectorNames.DgPubWalletMobile:
      return QR_CODE_IMG.dgPubWallet;
    case ConnectorNames.Metamask:
      return QR_CODE_IMG.metamaskConnect;
    case ConnectorNames.WalletConnect:
      return QR_CODE_IMG.walletConnect;
    default:
      return null;
  }
}
