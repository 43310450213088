import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import JSCookie from 'js-cookie';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useGoogleLogin } from '@react-oauth/google';
import { useMutation } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { DialogOverlay } from '@reach/dialog';
import {
  ERR_MESSAGE,
  OTP_COUNTDOWN_TIME,
  METAMASK_REDIRECT_TO_APP_LINK,
  DGPUB_PRIVACY_POLICY_URL,
  DGPUB_REDIRECT_TO_APP_LINK,
  TIME_SECOND_TO_MILISECOND,
  PRODUCT_ENV,
  LOGIN_TYPE,
} from '../../../constants';
import AuthApi from '../../../services/authen';
import { useAppDispatch } from '../../../store';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import useMessage from '../toast/UseMessage';
import TButton from '../Button/index';
import './styles.scss';
import LoginBtn from '../Button/LoginBtn';
import EmailIcon from '../../icons/EmailIcon';
import DggIcon from '../../icons/DggIcon';
import MetamaskIcon from '../../icons/MetamaskIcon';
import WalletConnectIcon from '../../icons/WalletConnectIcon';
import CloseDialogIcon from '../../icons/CloseDialogIcon';
import PrizeBoxIcon from '../../icons/PrizeBoxIcon';
import PrimaryInput from '../Input/PrimaryInput';
import LoginEmailInput from '../Input/LoginEmailInput';
import ShowQrcode from '../ShowQrCode/ShowQrCode';
import useDevice from '../../../hooks/useDevice';
import { HOME, QR_CODE_IMG } from '../../../../assets/imgs';
import Loading from '../Loading/Loading';
import { validateNewEmail } from '../../../utils/validator';
import processDataLogin from '../../../lib/processDataLogin';
import { toggleModalOpen, toggleModalClose } from '../../../store/features/openModalLogin';
import LoginWithWalletConnect from '../ShowQrCode/LoginWithWalletConnect';

import { metaMask } from '../../../connectors/metaMask';
import { useAuthContext } from '../../../context/AuthContext';
import { ConnectorNames } from '../../../lib/connectors';
import { setupLocalStorage } from '../../../lib';
import scholarshipApi from '../../../services/scholarship';
import MotionDialogContent from './MotionDialogContent';
import GoogleIcon from '../../icons/GoogleIcon';

function NewModalLogin(props) {
  // eslint-disable-next-line object-curly-newline
  const { show } = props;
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();
  const loginRef = useRef(null);
  const context = useWeb3React();
  const { connector, provider, account, chainId: selectedChainId } = context;
  const [userEmail, setUserEmail] = useState('');
  const isMobile = useDevice();
  const [enableSendOtp, setEnableSendOtp] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [showRetryBtn, setShowRetryBtn] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [otp, setOtp] = useState('');
  const { checkLogin, checkCreatedProfile, checkLinkedEmailAddress } = useAuthContext();
  const [loginMethod, setLoginMethod] = useState(ConnectorNames.Email);
  const { t } = useTranslation();
  const [isShowLoginGoogle, setIsShowLoginGoogle] = useState(true);

  useEffect(() => {
    function handleClickOutside(event) {
      if (loginRef.current && !loginRef.current.contains(event.target)) {
        dispatch(toggleModalClose());
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [loginRef]);

  useEffect(() => {
    if (validateNewEmail(userEmail)) {
      setEnableSendOtp(true);
    } else {
      setEnableSendOtp(false);
    }
  }, [userEmail]);

  useEffect(() => {
    const timeoutId = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, TIME_SECOND_TO_MILISECOND);
    return () => clearInterval(timeoutId);
  }, [timeLeft]);

  useEffect(() => {
    const { ethereum } = window;
    if (isMobile && ethereum) {
      setIsShowLoginGoogle(false);
    }
  }, [isMobile]);

  const handleSendOtp = async () => {
    dispatch(showLoading());
    try {
      const formData = {
        email: userEmail,
      };
      await AuthApi.register(formData);
      setTimeLeft(OTP_COUNTDOWN_TIME);
    } catch (error) {
      openMessageError(error?.response?.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleLoginEmail = async () => {
    dispatch(showLoading());
    try {
      const payload = {
        email: userEmail,
        token: otp,
      };
      if (referralCode) {
        payload.referralCode = referralCode;
      }
      const res = await AuthApi.loginEmail(payload);
      if (res?.status >= 200 && res?.status < 300) {
        const loginData = res?.data;
        processDataLogin(loginData);
        const response = await scholarshipApi.getProfile();
        if (response?.data) {
          setupLocalStorage(response?.data, loginMethod);
        } else {
          toast.error('Login error, please try again later');
          return;
        }
        checkLogin();
        checkCreatedProfile();
        checkLinkedEmailAddress();
        dispatch(toggleModalClose());
        toast.success(t('Login successfully!'));
        setOtp('');
        dispatch(hideLoading());
      } else {
        dispatch(hideLoading());
        openMessageError(
          error?.data?.message ||
            error?.reason ||
            error?.message ||
            'Opps! Something went wrong...',
        );
      }
    } catch (error) {
      console.log('error: ', error);
      dispatch(hideLoading());
      openMessageError(error?.response?.data?.message || ERR_MESSAGE);
    }
  };

  const handleLoginWallet = async () => {
    dispatch(showLoading());
    try {
      const date = new Date();
      const signer = provider.getSigner(account);
      const message = `${date.getTime()}.${account}`;
      const hashMessage = ethers.utils.hashMessage(message);
      const signature = await signer.signMessage(hashMessage);

      const payload = {
        walletAddress: account,
        message,
        signature,
        chainId: selectedChainId,
      };
      if (referralCode) {
        payload.referralCode = referralCode;
      }
      const res = await AuthApi.login(payload);
      if (res?.status >= 200 && res?.status < 300) {
        const loginData = res?.data;
        processDataLogin(loginData);
        const response = await scholarshipApi.getProfile();
        if (response?.data) {
          setupLocalStorage(response?.data, loginMethod);
        } else {
          toast.error('Login error, please try again later');
          return;
        }
        checkLogin();
        checkCreatedProfile();
        checkLinkedEmailAddress();
        dispatch(toggleModalClose());
        toast.success(t('Login successfully!'));
      } else {
        openMessageError(
          error?.data?.message ||
            error?.reason ||
            error?.message ||
            'Opps! Something went wrong...',
        );
      }
    } catch (error) {
      if (loginMethod === ConnectorNames.Metamask) {
        setShowRetryBtn(true);
      }
      if (connector?.deactivate) {
        connector.deactivate();
      } else {
        connector.resetState();
      }
      openMessageError(
        error?.data?.message || error?.reason || error?.message || 'Opps! Something went wrong...',
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (account && provider) {
      handleLoginWallet().catch((error) => {
        openMessageError('Error: ', error);
        // eslint-disable-next-line
        console.log('handleLoginWallet function error: ', error);
      });
    }
  }, [account, provider]);

  const handleLoginMetamask = () => {
    const { ethereum } = window;
    const hostName = window.location.hostname;

    if (ethereum) {
      setShowRetryBtn(false);
      metaMask.activate();
      setLoginMethod(ConnectorNames.Metamask);
    }
    if (isMobile && !ethereum) {
      window.open(`${METAMASK_REDIRECT_TO_APP_LINK}${hostName}`, '_blank', 'noopener,noreferrer');
    }
  };

  const handleLoginDggWallet = () => {
    const { ethereum } = window;
    const hostName = window.location.hostname;

    if (!isMobile) {
      setLoginMethod(ConnectorNames.DgPubWalletDesktop);
      return;
    }

    if (isMobile && ethereum) {
      setLoginMethod(ConnectorNames.DgPubWalletMobile);
      metaMask.activate();
    } else {
      window.open(`${DGPUB_REDIRECT_TO_APP_LINK}${hostName}`, '_blank', 'noopener,noreferrer');
    }
  };

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralCode = params.get('referralCode');
    const token = JSCookie.get('refreshToken');
    if (referralCode && !token) {
      setReferralCode(referralCode);
      dispatch(toggleModalOpen());
    }
  }, [location.search]);

  const loginGoogleMutation = useMutation(async (payload) => AuthApi.loginGoogleSocial(payload), {
    onSuccess: async (data) => {
      if (data?.status >= 200 && data?.status < 300) {
        const loginData = data?.data;
        processDataLogin(loginData);

        const response = await scholarshipApi.getProfile();
        if (response?.data) {
          setupLocalStorage(response?.data, ConnectorNames.Email, LOGIN_TYPE.GOOGLE);
        } else {
          dispatch(hideLoading());
          toast.error('Login error, please try again later');
          return;
        }
        checkLogin();
        checkCreatedProfile();
        checkLinkedEmailAddress();
        dispatch(hideLoading());
        dispatch(toggleModalClose());
        toast.success(t('Login successfully!'));
      } else {
        dispatch(hideLoading());
        toast.error(data?.response?.data?.message || data?.message || t('error.connectWallet'));
      }
    },
  });

  const loginGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const payload = {
          idToken: tokenResponse?.access_token,
        };
        if (referralCode) {
          payload.referralCode = referralCode;
        }
        dispatch(showLoading());
        loginGoogleMutation.mutate(payload);
      } catch (error) {
        toast.error('Login error, try again later!', error);
      }
    },
    onError: () => {
      toast.error('ERROR GOOGLE LOGIN');
    },
  });

  return (
    <AnimatePresence>
      {show && (
        <DialogOverlay onDismiss={() => dispatch(toggleModalClose())} className="dialog-overlay">
          <MotionDialogContent
            aria-label="Login popup"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
              y: 200,
              transition: {
                duration: 0.15,
              },
            }}
            className="motion-dialog-content"
          >
            <motion.div
              className={`new-modal-login hide-scrollbar ${!isMobile && 'shadow-primary-color'}`}
              initial={{ y: +30 }}
              animate={{ y: 0 }}
              style={{
                backgroundImage: `url(${HOME.bgModalLogin})`,
              }}
            >
              <div
                className="left-block-login"
                style={{
                  backgroundImage: `url(${HOME.bgModalLogin})`,
                }}
              >
                <p className="title-select-login-method">{t('login.selectMethod')}</p>
                <LoginBtn
                  onClick={() => setLoginMethod(ConnectorNames.Email)}
                  className={`btn-login-email ${
                    loginMethod === ConnectorNames.Email ? 'btn-has-border' : 'btn-no-border'
                  }`}
                >
                  <p>Email</p>
                  <EmailIcon />
                </LoginBtn>
                <p className="text-or">{t('login.or')}</p>
                <div
                  className={`${PRODUCT_ENV === 'dev' ? 'other-btn-login-dev' : 'other-btn-login'}`}
                >
                  <LoginBtn
                    onClick={handleLoginDggWallet}
                    className={`dgg-login ${
                      loginMethod === ConnectorNames.DgPubWalletDesktop ||
                      loginMethod === ConnectorNames.DgPubWalletMobile
                        ? 'btn-has-border'
                        : 'btn-no-border'
                    }`}
                  >
                    <p className="title-login-method">{t('wallet.defaut')}</p>
                    <DggIcon />
                  </LoginBtn>
                  {/* Hidden btn to use later  */}
                  {PRODUCT_ENV === 'dev' && (
                    <>
                      <LoginBtn
                        onClick={handleLoginMetamask}
                        className={`metamask-login ${
                          loginMethod === ConnectorNames.Metamask
                            ? 'btn-has-border'
                            : 'btn-no-border'
                        }`}
                      >
                        <p className="title-login-method">Metamask</p>
                        <MetamaskIcon />
                      </LoginBtn>

                      <LoginBtn
                        onClick={() => setLoginMethod(ConnectorNames.WalletConnect)}
                        className={`wallet-connect-login ${
                          loginMethod === ConnectorNames.WalletConnect
                            ? 'btn-has-border'
                            : 'btn-no-border'
                        }`}
                      >
                        <p className="title-login-method">Wallet Connect</p>
                        <WalletConnectIcon />
                      </LoginBtn>
                    </>
                  )}
                </div>
              </div>
              <div
                className="right-block-login"
                style={{
                  backgroundImage: `url(${HOME.bgModalLogin})`,
                }}
              >
                <div className="right-title-wrapper">
                  <div className="invite-code-title">
                    <p className="right-title">{t('login.inviteCode')}</p>
                    <PrizeBoxIcon />
                  </div>
                  <div
                    className="cursor-pointer"
                    aria-hidden
                    onClick={() => dispatch(toggleModalClose())}
                  >
                    <CloseDialogIcon />
                  </div>
                </div>
                <PrimaryInput
                  className="invite-code-input"
                  note={
                    <p className="note-input">
                      <span className="text-dangerous">*</span> {t('login.warning')}
                    </p>
                  }
                  placeholder={t('login.placeHolder.enterCode')}
                  onChange={(e) => setReferralCode(e.target.value)}
                  value={referralCode || ''}
                />
                {loginMethod === ConnectorNames.Email && (
                  <div className="login-email-wrapper">
                    <p className="login-title">{t('login.withEmail.title')}</p>
                    <p className="login-sub-title">{t('login.withEmail.subTitle')}</p>
                    <div className="login-input-wrapper">
                      <LoginEmailInput
                        onChange={(event) => setUserEmail(event.target.value)}
                        enableSendOtp={enableSendOtp}
                        handleSendOtp={handleSendOtp}
                        timeLeft={timeLeft}
                      />
                      <PrimaryInput
                        title="OTP"
                        placeholder={t('login.withEmail.sendOTP', {
                          seconds: `${OTP_COUNTDOWN_TIME}s`,
                        })}
                        onChange={(e) => setOtp(e.target.value)}
                        value={otp}
                      />
                      <div className="privacy-wrapper">
                        {t('By logging in, I hereby agree to the')}{' '}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={DGPUB_PRIVACY_POLICY_URL}
                          className="privacy-title"
                        >
                          {t('Privacy Policy')}
                        </a>
                        .
                      </div>
                    </div>
                    <TButton
                      disabled={!userEmail || otp.length < 6}
                      title={t('login.loginButton')}
                      onClick={handleLoginEmail}
                      width="full-width"
                    />

                    {isShowLoginGoogle && (
                      <div className="google-login-wrapper">
                        <div className="section-wrapper">
                          <div className="line-section-subBlue" />
                          <div className="divide-title">{t('Or by')}</div>
                          <div className="line-section-subBlue" />
                        </div>
                        <button
                          type="button"
                          className="google-btn-wrapper"
                          onClick={() => {
                            loginGoogle();
                          }}
                        >
                          <GoogleIcon />
                          <div className="google-title">Google</div>
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {loginMethod === ConnectorNames.DgPubWalletDesktop && <ShowQrcode />}
                {loginMethod === ConnectorNames.WalletConnect && (
                  <LoginWithWalletConnect handleSelectWalletConnect={handleSelectWalletConnect} />
                )}
                {loginMethod === ConnectorNames.Metamask && (
                  <div className="metamask-login-wrapper">
                    <img
                      src={QR_CODE_IMG.metamaskConnect}
                      alt="metamask-logo"
                      className="metamask-width"
                    />
                    <p className="txt-metamask-open">{t('login.withMetaMask.open')}</p>
                    <p className="txt-metamask-confirm">{t('login.withMetaMask.confirm')}</p>
                    {!showRetryBtn ? (
                      <Loading />
                    ) : (
                      <div onClick={handleLoginMetamask} className="retry-btn" aria-hidden="true">
                        {t('alert.retry')}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </motion.div>
          </MotionDialogContent>
        </DialogOverlay>
      )}
    </AnimatePresence>
  );
}

export default NewModalLogin;
