import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/common/Layout';
import './style.scss';
import TelegramIcon from '../../components/icons/TelegramIcon';
import TwiterIcon from '../../components/icons/TwiterIcon';
import FBIcon from '../../components/icons/FBIcon';
import PrimaryInput from '../../components/common/Input/PrimaryInput';
import PrimaryTextArea from '../../components/common/Input/PrimaryInputTextArea';
import TButton from '../../components/common/Button';
import { CONTACT_US_IMG } from '../../../assets/imgs';
import { validateNewEmail } from '../../utils/validator';
import tournamentApi from '../../services/tournament';
import { ERR_MESSAGE, SOCIAL_COMMUNITY_LINK } from '../../constants';

const variants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: {
    opacity: 0,
    height: 0,
  },
};

const TOAST_SEND_USER_RESPONSE = 'toast-send-response-id';

function ContactUs(props) {
  const { dataConnect } = props;
  const [userResponse, setUserResponse] = useState();
  const [responseSubmitted, setResponseSubmitted] = useState(false);
  const [showWrongEmail, setShowWrongEmail] = useState(false);
  const [isSubbmitedForm, setIsSubbmitedForm] = useState(false);
  const { t } = useTranslation();

  const SOCIAL_COMMUNITY_CONTACT = [
    {
      id: 1,
      name: 'Telegram',
      icon: <TelegramIcon color="#ffffff" />,
      bgColor: '#29A7E6',
      link: SOCIAL_COMMUNITY_LINK.TELEGRAM,
    },
    {
      id: 3,
      name: 'Twitter',
      icon: <TwiterIcon color="#ffffff" />,
      bgColor: '#181818',
      link: SOCIAL_COMMUNITY_LINK.TWITTER,
    },
    {
      id: 4,
      name: 'Facebook',
      icon: <FBIcon color="#ffffff" />,
      bgColor: '#4267B2',
      link: SOCIAL_COMMUNITY_LINK.FACEBOOK,
    },
  ];

  useEffect(() => {
    if (validateNewEmail(userResponse?.email)) {
      setShowWrongEmail(false);
    } else {
      setShowWrongEmail(true);
    }
  }, [userResponse?.email]);

  const handleSendResponse = async () => {
    toast.loading(t('sending'), {
      toastId: TOAST_SEND_USER_RESPONSE,
    });
    setResponseSubmitted(true);
    try {
      const data = await tournamentApi.sendUserResponse(userResponse);
      if (data?.status >= 200 && data?.status < 300) {
        toast.success(t('success.sendEmailSuccessfully'));
        setIsSubbmitedForm(true);
      } else {
        console.log(t('error.error'), data);
        if (typeof data?.response?.data?.message !== 'string') {
          toast.error(data?.response?.data?.message[0]);
        } else {
          toast.error(data?.response?.data?.message || data?.message || t('error.errorMessage'));
        }
      }
    } catch (error) {
      openMessageError(error?.response?.data?.message || ERR_MESSAGE);
    } finally {
      setResponseSubmitted(false);
      toast.dismiss(TOAST_SEND_USER_RESPONSE);
    }
  };

  return (
    <Layout dataConnect={dataConnect} link="/contact-us">
      <div
        style={{
          backgroundImage: `url(${CONTACT_US_IMG.bgContactUs})`,
          backgroundSize: `cover`,
          backgroundPosition: `center`,
          backgroundRepeat: `no-repeat`,
        }}
      >
        <div className="top-contact-wrapper">
          <div className="contact-title-wrapper">
            <img
              src={CONTACT_US_IMG.gameIcon1}
              alt="game-icon-1"
              className={`game-icon-1-img ${
                userResponse?.email && showWrongEmail ? 'wrong-email' : 'correct-email'
              }`}
            />
            <h1 className="head-title-contact-us">{t('header.contactUs')}</h1>
            <p className="content-contact-us">{t('contactUs.description')}</p>
          </div>
          <div className="right-form-wrapper">
            <div className="input-form-wrapper">
              {isSubbmitedForm ? (
                <div className="contact-us-success-wrapper">
                  <img
                    className="img-contact-us-success"
                    src={CONTACT_US_IMG.contactUsSuccess}
                    alt="contact-us-success"
                  />
                  <div>
                    <p className="contact-us-success-title">{t('contactUs.description1')}</p>
                    <p className="contact-us-success-content">{t('contactUs.description2')}</p>
                  </div>
                </div>
              ) : (
                <>
                  <PrimaryInput
                    title={
                      <div>
                        {t('profile.yourName')}
                        <span className="text-dangerous">*</span>
                      </div>
                    }
                    placeholder={t('profile.fullName')}
                    onChange={(e) => setUserResponse({ ...userResponse, name: e.target.value })}
                  />
                  <PrimaryInput
                    title={
                      <div>
                        Email<span className="text-dangerous">*</span>
                      </div>
                    }
                    placeholder="DGPub@gmail.com"
                    type="email"
                    onChange={(e) => {
                      setUserResponse({ ...userResponse, email: e.target.value });
                    }}
                  />
                  <AnimatePresence initial={false}>
                    {userResponse?.email && showWrongEmail && (
                      <motion.div
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={variants}
                        transition={{
                          duration: 0.2,
                        }}
                      >
                        <p className="text-dangerous invalid-text-msg">{t('warning.invalid')}</p>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <PrimaryTextArea
                    title={
                      <p>
                        {t('profile.yourMessage')}
                        <span className="text-dangerous">*</span>
                      </p>
                    }
                    placeholder={t('alert.helpYou')}
                    className="text-area-contact-us"
                    classNameTextArea="text-placeholder"
                    onChange={(e) => {
                      setUserResponse({ ...userResponse, message: e.target.value });
                    }}
                  />
                  <TButton
                    leftIcon={CONTACT_US_IMG.sendUsIcon}
                    className="distance-btn-send-us"
                    onClick={handleSendResponse}
                    disabled={
                      responseSubmitted ||
                      !userResponse?.name ||
                      !userResponse?.email ||
                      !userResponse?.message ||
                      showWrongEmail
                    }
                  >
                    {t('contactUs.sendUs')}
                  </TButton>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="bottom-contact-wrapper">
          <img src={CONTACT_US_IMG.gameIcon2} alt="game-icon-2" className="game-icon-2-img" />

          <div className="social-contact-wrapper">
            <p className="social-title">{t('contactUs.joinOurCommunity')}</p>
            <p className="social-content">
              {t('contactUs.description3')}
              <br />
              {t('contactUs.description4')}
            </p>
            <div className="social-link-wrapper">
              {SOCIAL_COMMUNITY_CONTACT?.map((i) => (
                <SocialContactCard key={i?.id} data={i} />
              ))}
            </div>
          </div>
          <img src={CONTACT_US_IMG.gameIcon3} alt="game-icon-3" className="game-icon-3-img" />
        </div>
      </div>
    </Layout>
  );
}

function SocialContactCard({ data }) {
  return (
    <a href={data?.link} target="_blank" rel="noreferrer">
      <div style={{ backgroundColor: data?.bgColor }} className="card-community-wrapper">
        {data?.icon}
        <p className="card-social-name">{data?.name}</p>
      </div>
    </a>
  );
}

export default ContactUs;
