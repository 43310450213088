import React, { useState } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { toast } from 'react-toastify';
import { ICONS } from '../../../../assets/imgs';
import { useAppDispatch } from '../../../store';
import tournamentApi from '../../../services/tournament';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import TButton from '../../common/Button';
import CONTRACT_ABI from '../../../abis/tournament.json';
import useActiveWeb3React from '../../../hooks/useActiveWeb3React';
import useSwitchNetwork from '../../../hooks/useSwitchNetwork';
import { CONTRACT_BY_CHAIN } from '../../../constants/contractConstant';

function RenderBtnResubmit({ data, getRedeemRewardHistory }) {
  const [enableBtnResubmit, setEnableBtnResubmit] = useState(true);
  const dispatch = useAppDispatch();
  const chainId = data?.chainId;
  const { provider: activeWeb3React } = useActiveWeb3React(chainId);
  const { switchNetwork } = useSwitchNetwork();
  const { t } = useTranslation();

  const handleClickReSubmit = async () => {
    dispatch(showLoading());
    try {
      setEnableBtnResubmit(false);
      await switchNetwork(chainId);
      const signer = activeWeb3React.getSigner();
      const { tournamentAddress } = CONTRACT_BY_CHAIN[chainId];
      const contract = new ethers.Contract(tournamentAddress, CONTRACT_ABI, signer);
      console.log(
        'CALL CONTRACT: ',
        new BigNumber(data?.amount).multipliedBy(10 ** data?.decimals).toFixed(),
        data?.id,
        data?.tokenAddress,
        data?.signature,
      );

      const createClaimTransaction = await contract.claimReward(
        new BigNumber(data?.amount).multipliedBy(10 ** data?.decimals).toFixed(),
        data?.id,
        data?.tokenAddress,
        data?.signature,
      );
      console.log('createClaimTransaction: ', createClaimTransaction);

      const finish = async () => {
        const response = await createClaimTransaction.wait();
        console.log('RESPONSE=====', response);
        if (response?.status === 0) {
          console.log('ERRORRR====');
          setEnableBtnResubmit(true);
          throw new Error('Tx failed');
        } else {
          const txHash = response?.transactionHash;
          const submitObj = {
            chainId,
            transactionHash: txHash,
          };
          console.log('SUCCEED====:', submitObj);
          await tournamentApi.sendClaimTicketLog(submitObj);
          if (getRedeemRewardHistory) {
            getRedeemRewardHistory();
          }
          setEnableBtnResubmit(true);
        }
      };
      toast.promise(finish(), {
        pending: t('tournament.pending'),
        success: t('success.claimTicketSucceeded'),
        error: t('tournament.claimTicketFailed'),
      });
    } catch (e) {
      setEnableBtnResubmit(true);
      toast.error(t('require.claimTicketFailed'));
      console.log('Error: ', e);
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <div className="btn-resubmit-wrapper">
      <TButton
        disabled={!enableBtnResubmit}
        leftIcon={ICONS.iConResubmit}
        title={t('myPrize.resubmit')}
        className="btn-resubmit"
        onClick={() => {
          handleClickReSubmit();
        }}
      />
    </div>
  );
}

export default RenderBtnResubmit;
