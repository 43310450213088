import React, { useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import useWalletConnect from '../../../hooks/useWalletConnect';
import './styles.scss';
import { QR_CODE_IMG } from '../../../../assets/imgs';

function ShowQrCode() {
  const { uri, getUri, clearUri } = useWalletConnect();
  const { t } = useTranslation();

  useEffect(() => {
    getUri();
    return () => {
      clearUri();
    };
  }, []);

  return (
    <div className="qr-code-wrapper">
      <p className="qr-code-title">{t('login.withWallet.qrCode')}</p>
      <div className="uri-qr-wrapper">
        {!uri && <div className="skeleton-uri skeleton-loading" />}
        {uri && (
          <QRCodeSVG
            size={275}
            imageSettings={{
              src: QR_CODE_IMG.dgPubWallet,
              height: 40,
              width: 40,
              excavate: true,
            }}
            bgColor="#ffffff"
            className="aspect-square"
            value={uri}
            includeMargin="true"
          />
        )}
      </div>
    </div>
  );
}

export default ShowQrCode;
