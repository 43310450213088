import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import CupIcon from '../../../icons/CupIcon';
import { TEAM, TOURNAMENT_STATISTIC } from '../../../../../assets/imgs';
import useMessage from '../../../common/toast/UseMessage';
import { useAppDispatch } from '../../../../store';
import { hideLoading, showLoading } from '../../../../store/features/loadingPage';
import tournamentApi from '../../../../services/tournament';
import { ERR_MESSAGE, VN_FLAG_IMG } from '../../../../constants';

function TopWinnerTab(props) {
  const { tournament } = props;
  const [topWinnerList, setTopWinnerList] = useState();
  const [peopleActiveInTableList, setPeopleActiveInTableList] = useState([]);
  const { openMessageError } = useMessage();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const getTopWinnerDetail = async () => {
    dispatch(showLoading());
    try {
      if (tournament?.id) {
        const res = await tournamentApi.getTournamentTopWinner(tournament?.id);
        setTopWinnerList(res.data);
      }
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    if (tournament?.id) {
      getTopWinnerDetail();
    }
  }, [tournament?.id]);

  useEffect(() => {
    if (topWinnerList?.length > 3) {
      setPeopleActiveInTableList(topWinnerList.slice(3));
    }
  }, [topWinnerList]);

  return (
    <div className="top-ticket-info-content">
      <div className="left-wrapper">
        <h4 className="active-title">{t('Prize')}</h4>
        <div className="most-active-wrapper">
          <PersonActiveItem
            data={topWinnerList ? topWinnerList[1] : {}}
            backgroundData={{
              id: 2,
              imgBadget: TOURNAMENT_STATISTIC.badgetTop2,
              colorBadget: '#A3AAB2',
            }}
          />
          <PersonActiveItem
            data={topWinnerList ? topWinnerList[0] : {}}
            backgroundData={{
              id: 1,
              imgBadget: TOURNAMENT_STATISTIC.badgetTop1,
              colorBadget: '#FFAE10',
            }}
            className="first-prize"
          />
          <PersonActiveItem
            data={topWinnerList ? topWinnerList[2] : {}}
            backgroundData={{
              id: 3,
              imgBadget: TOURNAMENT_STATISTIC.badgetTop3,
              colorBadget: '#B45905',
            }}
          />
        </div>
      </div>
      <div className="right-wrapper hide-scrollbar">
        <div className="table-statistic-style">
          <table>
            <thead>
              <tr />
              <tr>
                <td>{t('Rank')}</td>
                <td>{t('User')}</td>
                <td className="text-end">{t('Prize')}</td>
              </tr>
            </thead>
            <tbody>
              {peopleActiveInTableList?.map((item, index) => (
                <tr key={`body-${item.id}`}>
                  <td data-label="Rank" className="rank">
                    <p>#{index + 4}</p>
                  </td>
                  <td data-label="User" className="user-wrap">
                    <img src={TEAM.logoTeamDefault} alt="user-img" className="user-img" />
                    <p className="user-name">
                      {!item?.profile?.firstName && !item?.profile?.lastName
                        ? '---'
                        : `${item?.profile?.firstName} ${item?.profile?.lastName}`}
                    </p>
                    <img
                      src={item?.profile?.countryData?.image || VN_FLAG_IMG}
                      alt="dgpub-country"
                      className="country-img"
                    />
                  </td>
                  <td data-label="Played matches">
                    <div className="played-match-wrap">
                      <p className="played-number">{item?.prizesReceived}</p>
                      <CupIcon />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TopWinnerTab;

function PersonActiveItem({ data, backgroundData, className = '' }) {
  return (
    <div
      className={`persion-item-wrapper ${
        backgroundData?.id !== 1 && 'persion-item-below'
      } ${className}`}
    >
      <div className="item-badget-wrap">
        <img
          src={backgroundData?.userImg || TEAM.logoTeamDefault}
          alt="user-img"
          className={`${backgroundData?.id !== 1 ? 'user-img' : 'user-img-first'}`}
        />
        <img src={backgroundData?.imgBadget} alt="img-badget" className="img-badget" />
        <div className="number-position" style={{ backgroundColor: backgroundData?.colorBadget }}>
          {backgroundData?.id}
        </div>
      </div>
      <div className="name-country-wrap">
        <img
          src={data?.profile?.countryData?.image || VN_FLAG_IMG}
          alt="dgpub-country"
          className="country-img"
        />
        <div className="name">
          {!data?.profile?.firstName && !data?.profile?.lastName
            ? '---'
            : `${data?.profile?.firstName} ${data?.profile?.lastName}`}
        </div>
      </div>
      <div
        className="tournament-played-wrap"
        style={{ backgroundColor: backgroundData?.colorBadget }}
      >
        <div className="tour-played-number">{data?.prizesReceived}</div>
        <CupIcon />
      </div>
    </div>
  );
}
