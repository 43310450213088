import React from 'react';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ICONS, TEAM } from '../../../../assets/imgs';
import './styles.scss';

function TablePlayer(props) {
  const { t } = useTranslation();
  const { title, member, handleRemove } = props;
  return (
    <div className="table-custom">
      <Table borderless>
        <thead>
          <tr>
            <th colSpan={3}>
              <h6>{title}</h6>
            </th>
          </tr>
        </thead>
        <tbody>
          {member.length ? (
            member.map((item) => (
              <tr key={item.id}>
                <td>
                  <h6>
                    <img src={item.avatar || TEAM.avatarUser} alt="dgpub-network" />
                    {item.name}
                  </h6>
                </td>
                <td>{item.isOwner && <h6 className="owned">{t('team.owner')}</h6>}</td>
                <td>
                  {!item.isOwner && (
                    <img
                      src={ICONS.iconCloseTeam}
                      alt="dgpub-network"
                      onClick={(e) => handleRemove(e, item.userId)}
                      aria-hidden
                      className="icon-remove"
                    />
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr className="no-text">
              <td colSpan={3}>{t('team.thereAreNoInviteMember')}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default TablePlayer;
