import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useInfiniteQuery, useQueries } from '@tanstack/react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import Layout from '../../components/common/Layout';
import PrimaryInput from '../../components/common/Input/PrimaryInput';
import SecondaryButton from '../../components/common/Button/SecondaryButton';
import FilterIcon from '../../components/icons/FilterIcon';
import SearchIcon from '../../components/icons/SearchIcon';
import TournamentFilter from './TournamentFilter';
import CardTournament from '../../components/pages/Tournament/CardTournament';
import tournamentApi from '../../services/tournament';
import { CUSTOM_TIMEOUT_MILISEC } from '../../constants';
import TournamentFilterMobile from './TournamentFilterMobile';
import scholarshipApi from '../../services/scholarship';
import useDebounce from '../../hooks/useDebounce';

const variants = {
  open: { x: 0, opacity: 1 },
  closed: { x: -224, opacity: 0 },
};

function Explore(props) {
  const { dataConnect } = props;
  const { t } = useTranslation();
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [filter, setFilter] = useState({
    type: [],
    bracket: [],
    status: [],
    gameId: [],
  });
  const [listGame, setListGame] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedSearchValue = useDebounce(search, CUSTOM_TIMEOUT_MILISEC.DEBOUNCED_TIME);
  const pageSize = 4;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsShowFilter(false);
      } else {
        setIsShowFilter(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useQueries({
    queries: [
      {
        queryKey: ['getListGame'],
        queryFn: async () => {
          const response = await scholarshipApi.getListGame();
          setListGame(response?.data);
          return response?.data;
        },
      },
    ],
  });

  const getListTournaments = async ({ pageParam = 1 }) => {
    const filteredParams = {};
    Object.keys(filter).forEach((key) => {
      if (filter[key].length > 0) {
        filteredParams[key] = filter[key];
      }
    });
    if (debouncedSearchValue) {
      filteredParams.search = debouncedSearchValue;
    }
    const response = await tournamentApi.getTournaments({
      ...filteredParams,
      limit: pageSize,
      page: pageParam,
    });
    return { ...response?.data, prevOffset: pageParam };
  };

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['getListTournaments', filter, debouncedSearchValue],
    queryFn: getListTournaments,
    getNextPageParam: (lastPage) => {
      if (lastPage?.tournaments?.length === 0) {
        return null;
      }
      if (Number(lastPage?.page) >= Number(lastPage?.totalPage)) {
        return null;
      }
      return Number(lastPage?.page) + 1;
    },
  });

  const listTournamentsShow = data?.pages?.reduce((acc, page) => {
    if (page && page?.tournaments) {
      return [...acc, ...page?.tournaments];
    }
    return acc;
  }, []);

  const handleClearFilter = () => {
    setFilter({
      type: [],
      bracket: [],
      status: [],
      gameId: [],
    });
  };

  return (
    <Layout dataConnect={dataConnect} title={t('Explore Tournaments')} type={1} link="/tournament">
      <div className="filter_btn_wrapper">
        <SecondaryButton
          className="filter_btn"
          accessoriesLeft={<FilterIcon />}
          onClick={() => setIsShowFilter(!isShowFilter)}
        >
          {t('Filter')}
        </SecondaryButton>
        <PrimaryInput
          className="primary_search_input_wrapper"
          classNameInput="primary_search_input"
          accessoriesLeft={<SearchIcon width={24} height={24} />}
          placeholder={t('Search by tournament name')}
          value={search || ''}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="explore_tournament_wrapper">
        {isShowFilter && (
          <motion.div
            animate={isShowFilter ? 'open' : 'closed'}
            variants={variants}
            transition={{ duration: 0.2 }}
            className="filter_desktop"
          >
            <TournamentFilter
              handleClearFilter={handleClearFilter}
              setFilter={setFilter}
              listGame={listGame}
              filter={filter}
            />
          </motion.div>
        )}
        <div className={`list_tournament_wrapper ${isShowFilter ? 'opened_filter' : ''}`}>
          <InfiniteScroll
            dataLength={listTournamentsShow ? listTournamentsShow.length : 0}
            next={() => fetchNextPage()}
            hasMore={hasNextPage}
            loader={<div className="loading_progress">Loading...</div>}
          >
            <div
              className={`list_tournament_detail ${
                isShowFilter ? 'opened_tournament_detail' : 'closed_tournament_detail'
              }`}
            >
              {listTournamentsShow?.map((tournament) => (
                <CardTournament key={tournament?.id} card={tournament} hover={false} />
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>
      <TournamentFilterMobile
        filter={filter}
        setFilter={setFilter}
        showDialog={isShowFilter}
        setShowDialog={setIsShowFilter}
        listGame={listGame}
        handleClearFilter={handleClearFilter}
      />
    </Layout>
  );
}

export default Explore;
