import React from 'react';

function SocialEmailIcon(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={48} height={48} rx={8} fill="#002A42" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.98 7.066c-6.195.457-11.77 4.224-14.395 9.726-2.11 4.423-2.114 10.022-.009 14.434 2.505 5.25 7.034 8.607 12.873 9.543 1.74.278 5.356.312 7.044.065 2.775-.407 6.4-1.547 7.165-2.254 1.046-.967.745-2.833-.543-3.367-.74-.306-1.113-.267-2.43.256-.649.258-1.96.674-2.916.925-1.647.434-1.882.458-4.601.457-2.488-.001-3.025-.045-4.072-.33-3.773-1.032-6.477-3.338-8.14-6.945-.77-1.668-1.013-2.793-1.095-5.058-.11-3.029.408-5.19 1.804-7.524.837-1.4 3.049-3.586 4.46-4.408C22.694 9.924 29 10.387 32.88 13.669c1.306 1.105 2.092 2.092 2.768 3.474 1.346 2.756 1.405 6.309.145 8.85-1.093 2.207-3.381 3.809-4.449 3.116-.335-.218-.37-.341-.37-1.281 0-.822.876-8.63 1.279-11.395l.081-.56H27.8l-.09.448c-.05.247-.118.449-.151.449s-.313-.166-.622-.368c-3.606-2.359-8.821-.04-10.448 4.645-.489 1.408-.67 3.377-.461 4.995.43 3.33 1.898 5.595 4.389 6.776.895.425 1.111.465 2.488.465 1.357 0 1.592-.042 2.338-.42.456-.232 1.102-.684 1.435-1.006l.606-.585.561.59c2.164 2.278 6.516 1.785 9.484-1.074 1.538-1.482 2.76-3.55 3.33-5.63.42-1.54.46-5.126.074-6.648-.616-2.426-1.704-4.428-3.38-6.217-3.677-3.926-8.456-5.664-14.372-5.227zm2.228 13.223c.715.296 1.188.737 1.596 1.487.268.494.324.844.32 2.018-.003 1.288-.048 1.51-.486 2.404-1.058 2.16-3.076 3.227-4.427 2.34-1.924-1.262-1.992-5.951-.112-7.686.87-.803 2.026-1.012 3.11-.563z"
        fill="#fff"
      />
    </svg>
  );
}

export default SocialEmailIcon;
