import React from 'react';

function WalletIcon(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.496 8.875h-4.234c-1.97.004-3.567 1.56-3.57 3.482-.004 1.925 1.595 3.489 3.57 3.491H22.5v.306c0 3.36-2.036 5.346-5.483 5.346H7.984c-3.448 0-5.484-1.986-5.484-5.346V8.838C2.5 5.478 4.536 3.5 7.984 3.5h9.03c3.446 0 5.482 1.979 5.482 5.338v.037zM7.24 8.867H12.89a.756.756 0 00.762-.75.757.757 0 00-.769-.742H7.24a.756.756 0 00-.762.744.756.756 0 00.762.748z"
        fill="#003452"
      />
      <path
        opacity={0.4}
        d="M16.537 12.797c.21.95 1.044 1.62 1.996 1.603h3.25a.726.726 0 00.717-.734v-2.532a.728.728 0 00-.718-.734h-3.326c-1.083.003-1.958.902-1.956 2.01 0 .13.013.26.037.387z"
        fill="#003452"
      />
      <circle cx={18.5} cy={12.4} r={1} fill="#fff" />
    </svg>
  );
}

export default WalletIcon;
