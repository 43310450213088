import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';

function CarouselBanner(props) {
  const { listBanner } = props;
  const [count, setCount] = useState(0);
  const listImage = useRef(null);
  const playBanner = useRef(null);
  const listItem = document.getElementsByClassName('video-scholarship');
  const runVideo = document.getElementById(`check-${count}`);
  const addClass = (item) => item?.classList.add('active');
  const removeClass = (item) => item?.classList.remove('active');
  const runBanner = (time) => {
    playBanner.current = setTimeout(() => {
      const nextIndex = count < listBanner.length - 1 ? count + 1 : 0;
      setTimeout(removeClass, 10, listImage.current[count]);
      addClass(listImage.current[nextIndex]);
      setCount(nextIndex);
    }, time);
  };

  const handlePlayBanner = () => {
    runBanner(1000);
  };
  useEffect(() => {
    listImage.current = document.querySelectorAll('.image-banner-scholarship');
    addClass(listImage.current[0]);
    setCount(0);
  }, [listBanner]);
  useEffect(() => {
    if (
      listImage.current[count]?.classList.contains('active') &&
      listItem[count]?.classList.contains('checked')
    ) {
      runVideo.play();
    } else {
      runBanner(4000);
    }
    return () => clearTimeout(playBanner.current);
  }, [count]);
  return (
    <div className="main-image-scholarship">
      {listBanner.map((item, index) => (
        <div key={item.link} className="image-banner-scholarship">
          {item.mimeType === 'video/mp4' ? (
            <video
              id={`check-${index}`}
              className={`video-scholarship ${index === count && 'checked'}`}
              controls
              onEnded={handlePlayBanner}
            >
              <track kind="captions" />
              <source src={item.link} />
            </video>
          ) : (
            <img className="video-scholarship" src={item.link} alt="dgpub-network" />
          )}
        </div>
      ))}
    </div>
  );
}

export default CarouselBanner;
