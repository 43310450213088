import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TButton from '../../common/Button';
import { TOURNAMENT } from '../../../../assets/imgs';
import { getTranslatedValue } from '../../../lib/getTranslatedValue';
import BoxRewardIcon from '../../icons/BoxRewardIcon';
import TicketPrizeIcon from '../../icons/TicketPrizeIcon';
import GroupPeopleIcon from '../../icons/GroupPeopleIcon';
import GamePad from '../../icons/GamePad';
import { BRACKETS_STRING } from '../../../constants/bracket';
import LightNingIcon from '../../icons/LightNingIcon';
import useScreenWidth from '../../../hooks/useScreenWidth';

function InstantThumbnailTournament(props) {
  const { data } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const screenWidth = useScreenWidth();

  const goToDetail = (slug) => {
    history.push(`/tournament/${slug}`);
  };

  const renderJoinFee = () => {
    if (data?.ticketCollection) {
      return (
        <div>
          {data?.ticketCollection?.ticketType?.price || 0}&nbsp;
          {data?.ticketCollection?.paymentToken?.symbol}
        </div>
      );
    }
    return data?.join_fee ? (
      <div>{`${data?.join_fee || 0} ${data.applicationNetwork?.symbol}`}</div>
    ) : (
      <div>{t('feeToJoin')}</div>
    );
  };

  return (
    <div
      className="thumbnail-tour-wrapper"
      style={{
        backgroundImage:
          screenWidth < 768 ? 'none' : `url(${data?.image || TOURNAMENT.imgTournamentDefault})`,
        backgroundSize: `cover`,
        backgroundPosition: `center`,
        backgroundRepeat: `no-repeat`,
      }}
    >
      <div className="absolute-content">
        <img
          src={data?.image || TOURNAMENT.imgTournamentDefault}
          alt="dgp-thumbnail"
          className="bg-img-thumbnail"
        />
        <div className="right-content-wrapper">
          <div className="type-chain-wrapper">
            <img
              className="chain-logo"
              src={data?.ticketCollection?.paymentToken?.chain?.image}
              alt="chain-logo"
            />
            <div className="background__blue info-flex-wrap">
              <LightNingIcon />
              <p className="infor-tournament">{t('tournament.instantTournament')}</p>
            </div>
          </div>
          <div className="tournament-name-infor">{getTranslatedValue(data, 'name')}</div>
          <div className="game-name-infor">
            {t('Game:')} {data?.applicationGame?.name}
          </div>
          <div className="more-infor-wrapper">
            <div className="info-flex-wrap background__blue">
              <BoxRewardIcon />
              {data?.prize_amount || 0}&nbsp;{data?.gamePoint}
            </div>
            <div className="info-flex-wrap background__transparent">
              <TicketPrizeIcon />
              {renderJoinFee()}
            </div>
            <div className="info-flex-wrap background__transparent">
              <GamePad />
              <div>{data?.applicationBracket ?? ''}</div>
            </div>
            <div className="info-flex-wrap background__transparent">
              <GroupPeopleIcon />
              <div>
                {data?.numberTeam}
                {data?.applicationBracket !== BRACKETS_STRING.BATTLE_ROYALE &&
                  `/${data?.totalTeams ?? '-'}`}
              </div>
            </div>
          </div>
          <TButton
            onClick={() => goToDetail(data.slug)}
            title={t('Join Tournament')}
            className="btn-view-detail"
          />
        </div>
      </div>
    </div>
  );
}

export default InstantThumbnailTournament;
