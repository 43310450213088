import olAxios from '../api/Interceptors';

const scholarshipApi = {
  getListGame: () => olAxios.get('/games'),
  getDetailGame: (gameId) => olAxios.get(`/games/${gameId}`),
  getDetailGameBySlug: (slug) => olAxios.get(`/games/get-by-slug/${slug}`),
  post: (data) =>
    olAxios.post('/scholarship/application', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  getMyNfts: (params, slug) => olAxios.get(`/scholarship/games/${slug}/my-nft`, { params }),
  getNftsByAddress: (params, slug) =>
    olAxios.get(`/scholarship/games/${slug}/nft-by-address`, { params }),
  sendNfts: (data) => olAxios.post('/scholarship/nft', data),
  unListNfts: (data) => olAxios.post('/scholarship/nft/unlist', data),

  getStatusApply: (params) => olAxios.get('scholarship/application/status', { params }),

  getRequests: (params) => olAxios.get('/scholarship/request-by-wallet', { params }),
  getRequestDetail: (requestId) => olAxios.get(`scholarship/request/${requestId}`),
  updateRequestStatus: (requestId, slug) =>
    olAxios.post(`scholarship/request/approved/${slug}/${requestId}`),
  returnNfts: (data) => olAxios.post('/scholarship/request/refund', data),

  rejectRequest: (id) => olAxios.post(`scholarship/request/reject/${id}`),

  getTransactionTransferNft: (data) => olAxios.post('/scholarship/request/get-transaction/', data),
  getProfile: (params) => olAxios.get('/user/profile', { params }),
  postProfile: (data) => olAxios.post('/user/profile', data),
  changeStatusNftTransfer: (data, slug) => olAxios.post(`/scholarship/nft/status/${slug}`, data),
  getListRegion: () => olAxios.get('/scholarship/region'),
  getListGameGenre: () => olAxios.get('/scholarship/genre'),
  getListNetworks: (params) => olAxios.get('/networks', { params }),
};
export default scholarshipApi;
