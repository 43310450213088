import React from 'react';

function ClockIcon(props) {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M18.333 10.5A8.333 8.333 0 0110 18.833a8.334 8.334 0 118.333-8.333z"
        fill="#0FF"
      />
      <path
        d="M12.978 13.678a.63.63 0 01-.32-.087l-3.272-1.952a.628.628 0 01-.304-.537V6.896a.625.625 0 011.25 0v3.85l2.967 1.77a.627.627 0 01-.321 1.162z"
        fill="#0FF"
      />
    </svg>
  );
}

export default ClockIcon;
