import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import TwiterIcon from '../../icons/TwiterIcon';
import FBIcon from '../../icons/FBIcon';
import TelegramIcon from '../../icons/TelegramIcon';
import { DGPUB_SOCIAL_NETWORK_LINK } from '../../../constants';
import YoutubeIcon from '../../icons/YoutubeIcon';

const socialIcon = [
  {
    socialName: 'facebook',
    icon: <FBIcon />,
    url: DGPUB_SOCIAL_NETWORK_LINK.FACEBOOK,
  },
  {
    socialName: 'twitter',
    icon: <TwiterIcon />,
    url: DGPUB_SOCIAL_NETWORK_LINK.TWITTER,
  },
  {
    socialName: 'telegram',
    icon: <TelegramIcon />,
    url: DGPUB_SOCIAL_NETWORK_LINK.TELEGRAM,
  },
  {
    socialName: 'youtube',
    icon: <YoutubeIcon />,
    url: DGPUB_SOCIAL_NETWORK_LINK.YOUTUBE,
  },
];

function Footer(props) {
  const { t } = useTranslation();
  const { className = '' } = props;
  return (
    <div className={`footer ${className}`}>
      <p className="title-footer">
        {t('footer.copyright')} ® 2024 DG Pub.
        <br /> {t('footer.allRightsReserved')}
      </p>
      <div className="list-social">
        {socialIcon?.map((i) => (
          <SocialConnect key={i?.socialName} data={i} />
        ))}
      </div>
    </div>
  );
}

export default Footer;

function SocialConnect({ data }) {
  return (
    <a href={data?.url} target="_blank" rel="noreferrer">
      <div className="cursor-pointer">{data?.icon}</div>
    </a>
  );
}
