import React, { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom';
import { Table, Tooltip } from 'reactstrap';
import { BiCaretDown, BiCaretUp } from 'react-icons/bi';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/imgs';
import convertWallet from '../../../utils/convertWallet';
import ModalDelete from '../../common/Modal/ModalDelete';
import ModalNft from '../../common/ModalNft';
import { useAppDispatch } from '../../../store';
import scholarshipApi from '../../../services/scholarship';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import { ERR_MESSAGE } from '../../../constants';
import useMessage from '../../common/toast/UseMessage';
import './styles.scss';
import { convertStatusRentalRequest } from '../../../utils/convertColorStatus';
import ModalFilterRequest from './ModalFilterRequest';

const DATE_FORMAT = 'Do-MMM-YY, h:mm a';
const REQUESTING_STATUS = 0;
const REQUESTING_PENDING = 1;
const REQUESTING_REJECT = 3;

function RentalRequests(props) {
  const { id, account, tokenAccess } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { openMessageError } = useMessage();
  const [isCopy, setCopy] = useState(false);
  const [dataDetailNft, setDataDetailNft] = useState({});
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [indexDelete, setIndex] = useState();
  const [listRentalRequest, setListRentalRequest] = useState([]);

  const [status, setStatus] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const { t } = useTranslation();

  const filters = {
    status,
    startDate,
    endDate,
    createdAt,
  };

  const handleGetFilter = {
    setStatus,
    setStartDate,
    setEndDate,
    setCreatedAt,
  };

  const START_DATE = 'startDate';
  const END_DATE = 'endDate';
  const STATUS = 'status';
  const CREATED_AT = 'createdAt';

  const DESC = 'desc';
  const ASC = 'asc';

  const [orderBy, setOrderBy] = useState(CREATED_AT);

  const [orderType, setOrderType] = useState(DESC);

  const handleSort = (name) => {
    if (name === orderBy) {
      setOrderType(orderType === DESC ? ASC : DESC);
    } else {
      setOrderType(ASC);
    }
    setOrderBy(name);
  };

  const getRequests = async () => {
    dispatch(showLoading());
    const params = {
      gameId: id,
      walletAddress: account,
      orderBy,
      orderType,
      status,
      startDate,
      endDate,
      createdAt,
    };
    try {
      const res = await scholarshipApi.getRequests(params);
      const { data } = res;
      setListRentalRequest(data);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (tokenAccess) {
      getRequests();
    }
  }, [id, tokenAccess, orderBy, orderType, status, startDate, endDate, createdAt]);

  const handleCopy = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  const handleClickItem = (itemId) => {
    history.push(`/transfer/${itemId}`);
  };

  const rejectRequest = async (index) => {
    dispatch(showLoading());
    try {
      const idReject = listRentalRequest[index].id;
      await scholarshipApi.rejectRequest(idReject);
      const dataRental = [...listRentalRequest];
      dataRental[index].status = REQUESTING_REJECT;
      setListRentalRequest(dataRental);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleDeleteRequest = (index) => {
    rejectRequest(index);
  };

  const handleOpenDelete = (e, index) => {
    e.stopPropagation();
    setIndex(index);
    setShow(true);
  };

  const handleClickNft = (e, nft) => {
    e.stopPropagation();
    setDataDetailNft(nft);
    setIsVisibleModal(true);
  };

  const handleCloseModal = () => {
    setIsVisibleModal(false);
  };
  return (
    <div className="wrap-my-nfts-rental">
      <ModalNft
        isVisible={isVisibleModal}
        handleCloseModal={handleCloseModal}
        dataDetailNft={dataDetailNft}
      />
      <ModalDelete
        title={t('nft.rejected')}
        description={t('nft.confirm.reject')}
        show={show}
        setShow={setShow}
        indexDelete={indexDelete}
        handleDelete={handleDeleteRequest}
      />
      <ModalFilterRequest
        show={showFilter}
        setShow={setShowFilter}
        filters={filters}
        handleGetFilter={handleGetFilter}
      />
      <div className="wrap-table-nfts">
        <Table className="table-nft-request">
          <thead>
            <tr>
              <th>
                <h6 className="no-select">{t('scholarship.scholarWallet')}</h6>
              </th>
              <th>
                <h6 className="no-select">NFTs</h6>
              </th>
              <th>
                <h6
                  className="no-select cursor-pointer"
                  onClick={() => handleSort(START_DATE)}
                  aria-hidden
                >
                  {t('filter.start')}{' '}
                  <span>
                    {orderBy === START_DATE && orderType === ASC ? (
                      <BiCaretUp
                        className={
                          orderBy === START_DATE
                            ? 'ac-icon-sort-nft icon-sort-nft'
                            : 'icon-sort-nft'
                        }
                      />
                    ) : (
                      <BiCaretDown
                        className={
                          orderBy === START_DATE
                            ? 'ac-icon-sort-nft icon-sort-nft'
                            : 'icon-sort-nft'
                        }
                      />
                    )}
                  </span>
                </h6>
              </th>
              <th>
                <h6
                  className="no-select cursor-pointer"
                  onClick={() => handleSort(END_DATE)}
                  aria-hidden
                >
                  {t('filter.end')}{' '}
                  <span>
                    {orderBy === END_DATE && orderType === ASC ? (
                      <BiCaretUp
                        className={
                          orderBy === END_DATE ? 'ac-icon-sort-nft icon-sort-nft' : 'icon-sort-nft'
                        }
                      />
                    ) : (
                      <BiCaretDown
                        className={
                          orderBy === END_DATE ? 'ac-icon-sort-nft icon-sort-nft' : 'icon-sort-nft'
                        }
                      />
                    )}
                  </span>
                </h6>
              </th>
              <th>
                <h6
                  className="no-select cursor-pointer"
                  onClick={() => handleSort(STATUS)}
                  aria-hidden
                >
                  {t('status.default')}{' '}
                  <span>
                    {orderBy === STATUS && orderType === ASC ? (
                      <BiCaretUp
                        className={
                          orderBy === STATUS ? 'ac-icon-sort-nft icon-sort-nft' : 'icon-sort-nft'
                        }
                      />
                    ) : (
                      <BiCaretDown
                        className={
                          orderBy === STATUS ? 'ac-icon-sort-nft icon-sort-nft' : 'icon-sort-nft'
                        }
                      />
                    )}
                  </span>
                </h6>
              </th>
              <th>
                <h6
                  className="no-select cursor-pointer"
                  onClick={() => handleSort(CREATED_AT)}
                  aria-hidden
                >
                  {t('scholarship.requestTime')}{' '}
                  <span>
                    {orderBy === CREATED_AT && orderType === ASC ? (
                      <BiCaretUp
                        className={
                          orderBy === CREATED_AT
                            ? 'ac-icon-sort-nft icon-sort-nft'
                            : 'icon-sort-nft'
                        }
                      />
                    ) : (
                      <BiCaretDown
                        className={
                          orderBy === CREATED_AT
                            ? 'ac-icon-sort-nft icon-sort-nft'
                            : 'icon-sort-nft'
                        }
                      />
                    )}
                  </span>
                </h6>
              </th>
              <th>
                <h6 style={{ textAlign: 'end' }}>
                  <img
                    src={ICONS.iconFilter}
                    alt="img"
                    className="cursor-pointer"
                    onClick={() => setShowFilter(!showFilter)}
                    aria-hidden
                  />
                </h6>
              </th>
            </tr>
          </thead>
          {listRentalRequest.length > 0 ? (
            listRentalRequest.map((item, index) => (
              <tbody
                key={item.id}
                className="row-nft cursor-pointer"
                onClick={() => handleClickItem(item.id)}
                aria-hidden="true"
              >
                <tr>
                  <td>
                    {item.walletAddress && (
                      <div className="d-flex align-items-center" id="tooltipCopyWallet">
                        <h6 id="copyId">{convertWallet(item.walletAddress?.toUpperCase())}</h6>
                        <div onClick={(e) => e.stopPropagation()} aria-hidden>
                          <CopyToClipboard text={item.walletAddress} onCopy={handleCopy}>
                            <img className="px-1" src={ICONS.iconCopy} alt="icon" />
                          </CopyToClipboard>
                          <Tooltip target="tooltipCopyWallet" placement="top-start" isOpen={isCopy}>
                            {t('copy.copied')}
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </td>
                  <td>
                    {item.nfts.map((nft) =>
                      nft.type_media === 'video' ? (
                        <video autoPlay playsInline loop width="40" height="40" key={nft.id}>
                          <source src={nft.img} type="video/mp4" />
                          <track kind="captions" />
                        </video>
                      ) : (
                        <img
                          key={nft.id}
                          src={nft.img}
                          alt="img"
                          className="nft-image"
                          onClick={(e) => handleClickNft(e, nft)}
                          aria-hidden="true"
                        />
                      ),
                    )}
                  </td>
                  <td>
                    <h6 className="row-nft-duration">
                      {item.startDate ? moment(item.startDate).format(DATE_FORMAT) : 'N/A'}
                    </h6>
                  </td>
                  <td>
                    <h6 className="row-nft-duration">
                      {item.endDate ? moment(item.endDate).format(DATE_FORMAT) : 'N/A'}
                    </h6>
                  </td>
                  <td>
                    <h6 style={{ color: convertStatusRentalRequest(item.status)?.color }}>
                      {convertStatusRentalRequest(item.status)?.name}
                    </h6>
                  </td>
                  <td>
                    <h6 className="row-nft-duration">{moment(item.createdAt).fromNow()}</h6>
                  </td>
                  <td>
                    {(item.status === REQUESTING_STATUS || item.status === REQUESTING_PENDING) && (
                      <img
                        src={ICONS.iconReject}
                        alt="dgpub-network"
                        className="cursor-pointer"
                        onClick={(e) => handleOpenDelete(e, index)}
                        aria-hidden="true"
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            ))
          ) : (
            <tbody>
              <tr>
                <td colSpan={7}>
                  <h6 className="mb-5 no-data">{t('notification.rentalRequest')}</h6>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
    </div>
  );
}

export default RentalRequests;
