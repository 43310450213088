import React, { useState, useEffect } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  STATUS_TOURNAMENT_DETAIL,
  SUB_LINK_TOURNAMENT_BATTLE_ROYALE,
  SUB_LINK_TOURNAMENT_DETAILS,
  SUB_LINK_TOURNAMENT_LIVE_NEW,
  SUB_LINK_TOURNAMENT_PAST,
} from '../../../../constants';
import Bracket from './Bracket';
import Detail from './Detail';
import Participant from './Participant';
import Prizing from './Prizing';
import './styles.scss';
import Winner from './Winner';
import { BRACKETS_STRING } from '../../../../constants/bracket';
import StatisticsTab from './StatisticsTab';
import { getTranslatedValue } from '../../../../lib/getTranslatedValue';

function InfoBox(props) {
  const { tournament, id, dataBracket, getBrackets } = props;
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [tabLinkTournament, setTabLinkTournament] = useState([]);

  useEffect(() => {
    if (tournament) {
      if (tournament.applicationBracket === BRACKETS_STRING.BATTLE_ROYALE) {
        setTabLinkTournament(SUB_LINK_TOURNAMENT_BATTLE_ROYALE);
        return;
      }
      if (tournament.status === STATUS_TOURNAMENT_DETAIL.Done) {
        setTabLinkTournament(SUB_LINK_TOURNAMENT_PAST);
      } else {
        setTabLinkTournament(SUB_LINK_TOURNAMENT_LIVE_NEW);
      }
    }
  }, [tournament]);

  useEffect(() => {
    if (tab === 1) {
      getBrackets();
    }
  }, [id, tab]);

  const handleClickTab = (index) => {
    setTab(index);
  };

  return (
    <div className="info-detail">
      <div className="mb-3 list-info-wrapper hide-scrollbar">
        {tabLinkTournament.map((link) => (
          <div
            key={link.tab}
            className={`info-item ${link.tab === tab ? 'info-tab-active' : 'info-tab-hover'}`}
          >
            <h4
              className="info-title-name"
              onClick={() => handleClickTab(link.tab)}
              aria-hidden="true"
            >
              {t(link.name)}
            </h4>
          </div>
        ))}
      </div>
      <div className="separate-line" />

      <TabContent activeTab={tab}>
        <TabPane tabId={SUB_LINK_TOURNAMENT_DETAILS.Details} className="info-content">
          <div className="wrap-info-content-scroll">
            <Detail description={getTranslatedValue(tournament, 'description')} />
          </div>
        </TabPane>
        <TabPane tabId={SUB_LINK_TOURNAMENT_DETAILS.Brackets} className="info-content">
          {dataBracket && <Bracket tournament={tournament} id={id} dataBracket={dataBracket} />}
        </TabPane>
        <TabPane tabId={SUB_LINK_TOURNAMENT_DETAILS.Participants} className="info-content">
          <div className="wrap-info-content-scroll">
            <Participant id={id} />
          </div>
        </TabPane>
        {tournament.status === STATUS_TOURNAMENT_DETAIL.Done ? (
          <TabPane tabId={SUB_LINK_TOURNAMENT_DETAILS.Winners} className="info-content">
            <div className="wrap-info-content-scroll">
              <Winner
                tournament={tournament}
                id={id}
                prizePool={tournament.prize_amount}
                token={
                  tournament?.ticketCollectionId
                    ? tournament?.ticketCollection?.gamePoint?.pointSymbol
                    : tournament?.applicationNetwork?.symbol
                }
              />
            </div>
          </TabPane>
        ) : (
          <TabPane tabId={SUB_LINK_TOURNAMENT_DETAILS.Prizing} className="info-content">
            <div className="wrap-info-content-scroll">
              <Prizing
                tournament={tournament}
                id={id}
                prizePool={tournament.prize_amount}
                token={
                  tournament?.ticketCollectionId
                    ? tournament?.ticketCollection?.gamePoint?.pointSymbol
                    : tournament?.applicationNetwork?.symbol
                }
              />
            </div>
          </TabPane>
        )}
        <TabPane
          tabId={SUB_LINK_TOURNAMENT_DETAILS.Statistics}
          className="instant-statistic-content"
        >
          <StatisticsTab tournament={tournament} />
        </TabPane>
      </TabContent>
    </div>
  );
}

export default InfoBox;
