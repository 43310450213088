import React from 'react';
import { TOURNAMENT } from '../../../../assets/imgs';

function InstantCardTournament({ data, activeSlide = false }) {
  return (
    <div className={`instant-card-tournament ${activeSlide && 'instant-card-active'}`}>
      <img
        src={data?.image || TOURNAMENT.instantBanner}
        alt="tour-banner"
        className="img-card-banner"
      />
    </div>
  );
}

export default InstantCardTournament;
