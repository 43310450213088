import React from 'react';
import { useHistory } from 'react-router-dom';
import { TEAM } from '../../../../../assets/imgs';
import InputField from '../../../common/Input';
import SelectCustom from '../../../common/SelectCustom/index';
import TButton from '../../../common/Button';

function SelectTeam(props) {
  const {
    listMyTeam,
    teamId,
    setTeamId,
    currentMember,
    email,
    setEmail,
    inviteEmail,
    handleJoinTournament,
    titleSelectTeam,
    teamSize,
  } = props;
  const history = useHistory();
  return (
    <>
      <h6 className="color-primary mb-2" style={{ fontSize: '24px' }}>
        {titleSelectTeam}
      </h6>
      {listMyTeam.length ? (
        <>
          <SelectCustom
            title="Select a Team"
            items={listMyTeam}
            selectedItem={teamId}
            setSelectedItem={setTeamId}
          />
          <div className="list-member-team">
            <p>Players in Team {teamId && `${currentMember.length}/${teamSize}`}</p>
            <div className="list-member">
              {currentMember &&
                currentMember.map((member) => (
                  <p className="member" key={member.id}>
                    <img src={member.avatar || TEAM.avatarUser} alt="dgpub-network" /> {member.name}
                  </p>
                ))}
            </div>
          </div>
          <div className="invite-email">
            <InputField
              placeholder="Enter email address"
              title="Need to invite more friends? Invite by email!"
              value={email}
              onChange={setEmail}
              className="input-email"
            />
            <div className="btn-invite" onClick={inviteEmail} aria-hidden>
              Invite
            </div>
          </div>
        </>
      ) : (
        <p className="text-create-team">You currently do not have a team, please create a team</p>
      )}
      {listMyTeam.length ? (
        <TButton
          title="Join Tournament"
          className={`mb-3 ${teamId ? '' : 'btn-disabled'}`}
          onClick={handleJoinTournament}
          width="full-width"
        />
      ) : (
        <TButton
          title="Create Team"
          className="mb-3"
          onClick={() => history.push('/my-team/create')}
          width="full-width"
        />
      )}
    </>
  );
}

export default SelectTeam;
