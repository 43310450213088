import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import GameCard from '../../components/common/GameCard';
import Layout from '../../components/common/Layout';
import useMessage from '../../components/common/toast/UseMessage';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import './styles.scss';
import scholarshipApi from '../../services/scholarship';
import Form from '../../components/pages/ScholarshipDetail/Form';
import {
  APPLY_APPROVED,
  APPLY_APPROVING,
  APPLY_NOT_SEEN,
  APPLY_REJECT,
  ERR_MESSAGE,
  SUB_LINK,
} from '../../constants';
import ApplySuccess from '../../components/pages/ScholarshipDetail/ApplySuccess';
import CarouselBanner from '../../components/pages/ScholarshipDetail/CarouselBanner';
import { toggleModalOpen } from '../../store/features/openModalLogin';
import { useAuthContext } from '../../context/AuthContext';

function ScholarshipDetail(props) {
  const { t } = useTranslation();
  const { dataConnect } = props;
  const { openMessageError } = useMessage();
  const dispatch = useAppDispatch();
  const { slug } = useParams();

  const [tab, setTab] = useState(0);
  const [data, setData] = useState(null);
  const [games, setGames] = useState(null);

  const [statusApply, setStatusApply] = useState(APPLY_NOT_SEEN);
  const [info, setInfo] = useState([]);
  const [id, setId] = useState();
  const history = useHistory();
  const { isLogin, userEmail, walletAccount } = useAuthContext();

  const handleConnectLinkWallet = () => {
    if (userEmail) {
      dataConnect.linkWallet();
    } else {
      dispatch(toggleModalOpen());
    }
  };

  const getDetailGame = async () => {
    dispatch(showLoading());
    try {
      const res = await scholarshipApi.getDetailGameBySlug(slug);
      setId(res?.data?.id);
      setData(res.data);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const getGames = async () => {
    dispatch(showLoading());
    try {
      const res = await scholarshipApi.getListGame();
      const listGames = res.data;
      setGames(listGames.filter((game) => game.id !== id));
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (slug) {
      getDetailGame().catch((error) => {
        openMessageError(t('error.error'), error);
      });
    }
  }, [slug]);

  useEffect(() => {
    if (id) {
      getGames().catch((error) => {
        openMessageError(t('error.error'), error);
      });
    }
  }, [id]);

  const goToDetail = (value) => {
    history.push(`/scholarships/${value}`);
    window.scroll(0, 0);
  };

  const renderItem = (game, key) => (
    <Col lg="3" md="4" sm="6" className="mb-4" key={key}>
      <GameCard game={game} goToDetail={() => goToDetail(game.slug)} />
    </Col>
  );

  const getStatusApply = async () => {
    try {
      const params = {
        walletAddress: walletAccount,
        gameId: id,
      };
      const res = await scholarshipApi.getStatusApply(params);
      setStatusApply(res.data.status);
      setInfo(res.data.info);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    }
  };

  useEffect(() => {
    if (isLogin && id) {
      getStatusApply().catch((error) => {
        openMessageError(t('error.error'), error);
      });
    }
  }, [id]);

  return (
    <Layout title={data?.name} type={1} dataConnect={dataConnect} link="/scholarships">
      {data && (
        <div className="scholarship-detail">
          <Row className="mb-5">
            <Col lg="12" md="12" className="wrap-image-area">
              <div className="banner-scholarship-detail">
                <CarouselBanner listBanner={data.images} />
                <div className="info">
                  <div>
                    <h5>{t('scholarship.gameGenre')}</h5>
                    <h2>{data.genre}</h2>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg="12" md="12">
              <div className="info-detail">
                <Row className="mb-4 info-detail-top">
                  {SUB_LINK.map((link, index) => (
                    <Col key={link.key} className="sub-link-scholarship">
                      <h4
                        onClick={() => setTab(index)}
                        className={link.name === SUB_LINK[tab].name ? 'tab-active' : 'tab-hover'}
                        aria-hidden="true"
                      >
                        {t(link.name)}
                      </h4>
                    </Col>
                  ))}
                </Row>
                <div className="info-content">
                  <div
                    className="wrap-info-content-scroll"
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{
                      __html: data[SUB_LINK[tab].key] ?? '',
                    }}
                    aria-hidden
                  />
                </div>
              </div>
            </Col>
          </Row>

          {data?.isScholarship && (
            <Row className="mb-5">
              {Number(walletAccount) ? (
                <div className="apply-form">
                  <h2 className="mb-4">{t('scholarship.applyForScholarship')}</h2>
                  {(statusApply === APPLY_NOT_SEEN || statusApply === APPLY_REJECT) && (
                    <Form
                      account={walletAccount}
                      id={id}
                      setStatusApply={setStatusApply}
                      mode={data?.accountCreateMode}
                    />
                  )}
                  {statusApply === APPLY_APPROVING && (
                    <ApplySuccess type={APPLY_APPROVING} mode={data?.accountCreateMode} />
                  )}
                  {statusApply === APPLY_APPROVED && (
                    <ApplySuccess
                      type={APPLY_APPROVED}
                      data={info}
                      mode={data?.accountCreateMode}
                    />
                  )}
                </div>
              ) : (
                <div className="continue-btn-wrapper">
                  <Col lg="3" md="4" xs="12">
                    <h1
                      className="btn-stroke-fill"
                      onClick={handleConnectLinkWallet}
                      aria-hidden="true"
                    >
                      {userEmail ? t('require.linkWallet') : t('require.logIn')}
                    </h1>
                  </Col>
                </div>
              )}
            </Row>
          )}

          <Row>
            {games?.length > 0 && <h4 className="mb-4">{t('moreGame')}</h4>}

            {games && <>{games.map((game, key) => renderItem(game, key))}</>}
          </Row>
        </div>
      )}
    </Layout>
  );
}

export default ScholarshipDetail;
